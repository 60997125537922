const DailyEmoteElement = ({ editable, checked, value, img, onChange }) => {
    return (
        <div className="form-check form-check-inline emotes">
            <label>
                <input disabled={!editable} defaultChecked={checked} className="form-check-input" type="radio" name="mood_score" value={value}
                       onChange={e => onChange(e, e.target.value)}
                />
                <img src={img}/>
            </label>
            <br/>
            <label className="form-check-label">{value}</label>
        </div>
    )
}

export default DailyEmoteElement
