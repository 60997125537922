import "./LayoutElement.module.css"
import React, {useState} from "react";
import {Link, Outlet} from 'react-router-dom';
import {ImageService, BreadcrumbContextSetter} from './index'

const LayoutElement = () => {

    const notificationCount = 0;
    const [breadcrumbs, setBreadcrumbs] = useState({});

    return (
        <>
            <nav className="pb-0 navbar navbar-expand-lg navbar-light bg-light flex-column">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={ ImageService.getImage('hanze_logo.png') } width="30" alt="Naar homepagina"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav w-100">
                            <li className="nav-item">
                                <Link className="nav-link active" to="/dashboard">Dashboard</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/profile">Profiel</Link>
                            </li>

                            <li className="nav-item">
                                <Link type="button" to="/notifications" className="btn btn-light position-relative">
                                    <i className="bi bi-bell-fill"/>
                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                        { notificationCount }
                                        <span className="visually-hidden">unread messages</span>
                                    </span>
                                </Link>
                            </li>

                            <li className="nav-item ms-lg-auto ms-md-auto">
                                <Link className="nav-link float-end" to="/logout">Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="breadcrumb" className="container-fluid bg-light align-items-baseline">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            { Object.keys(breadcrumbs).map((name, index) => {
                                const path = breadcrumbs[name];
                                const isLast = (index+1) === Object.keys(breadcrumbs).length;
                                return <li key={index} className={`breadcrumb-item${isLast ? ' active' : ''}`}>
                                    { isLast || path === null ? name : <Link to={path}>{name}</Link> }
                                </li>
                            }) }
                        </ol>
                    </nav>
                </div>
            </nav>

            <main className="pb-5">
                <BreadcrumbContextSetter.Provider value={setBreadcrumbs}>
                    <Outlet />
                </BreadcrumbContextSetter.Provider>
            </main>

            <footer className="bg-light text-center text-lg-start mt-5 fixed-bottom">
                <div className="text-center bg-orange text-white p-3">
                    © {new Date().getFullYear()} Copyright
                    |&nbsp;
                    <a className="text-white" href="https://opendidact.nl/wiki">Opendidact Wiki</a>
                    &nbsp;|&nbsp;
                    <a className="text-white" href="https://opendidact.nl/wordpress">Opendidact Blog</a>
                    <br/>
                    <a className="text-white" href="https://forms.gle/P8RTB5xFciWPpSR78">Probleem? Meld het hier</a>
                </div>
            </footer>
        </>
    )
}

export { LayoutElement };
