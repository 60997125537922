import React from "react";
import "./loginComponent.css";
import {Api, EventService, UserContext, UserContextSetter} from "../index";
import {UserService} from "../services/api/UserService";
import LoadingScreen from "../loading/LoadingScreen";
import SpinnerElement from "../loading/SpinnerElement";
import UserEventService from "../services/events/UserEventService";

class LoginRequired extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: {},
            loggedIn: false,
            loading: true,
            loggingIn: false,
            email: '',
            password: ''
        }
    }

    checkLoggedIn() {
        const token = localStorage.getItem('token');
        if (!token) this.setState({
            loading: false,
        });
        else UserService.getSelf().then(u => {
            this.setState({
                loading: false,
                user: u,
                loggedIn: true,
            });
        }).catch(res => {
            this.setState({
                loading: false,
            })
        });
    }

    loginUser() {
        return Api.authenticate(this.state.email, this.state.password);
    }

    handleLogin(e) {
        this.setState({
            loggingIn: true,
        })
        this.loginUser().then(token => {
            if (token) {
                localStorage.setItem("token", token);
                EventService.restart()
                UserService.getSelf().then(u => {
                    this.setState({
                        loading: false,
                        loggingIn: false,
                        user: u,
                        loggedIn: true,
                    })
                }).catch(res => {
                    this.setState({
                        loading: false,
                        loggingIn: false,
                    })
                });
            } else {
                this.setState({
                    loggingIn: false,
                    loading: false,
                })
                localStorage.removeItem("token")
                alert("wrong credentials")
            }
        });
    }

    registerListeners() {
        UserEventService.handleStateWithId(this, this.state.user.id, 'user')
    }

    componentWillUnmount() {
        if (this.state.user.id) UserEventService.unsubscribeWithId(this.state.user.id, this)
    }

    componentDidMount() {
        this.checkLoggedIn()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.user.id && (prevState.user.id !== this.state.user.id)) {
            UserEventService.unsubscribeWithId(prevState.user.id, this)
            this.registerListeners()
        }
    }

    setUser(user) {
        this.setState({
            user: user
        })
    }

    render() {

        return (
            this.state.loggedIn

                ?

                <UserContextSetter.Provider value={(user) => this.setUser(user)}>
                    <UserContext.Provider value={this.state.user}>
                        {this.props.children}
                    </UserContext.Provider>
                </UserContextSetter.Provider>

                :

                <div style={{
                    height: '100vh'
                }} className={`m-0 p-0 w-100 d-flex justify-content-center align-items-center${this.state.loggingIn ? ' grayed-out' : ''}`}>

                    { this.state.loading ? <LoadingScreen transparent={false} /> : <></> }

                    <div id="login-container" className="h-75 d-flex flex-column justify-content-center">
                        <h1 className="text-center mb-5">Login</h1>
                        <div className="form-outline mb-4">
                            <input type="email" id="form2Example1" className="form-control"
                                   onChange={e => this.setState({
                                       email: e.target.value
                                   })}
                                   onKeyPress={e => {
                                       if (e.key === 'Enter') this.handleLogin()
                                   }}
                            />
                            <label className="form-label" htmlFor="form2Example1">Email address</label>
                        </div>

                        <div className="form-outline mb-4">
                            <input type="password" id="form2Example2" className="form-control"
                                   onChange={e => this.setState({
                                       password: e.target.value
                                   })}
                                   onKeyPress={e => {
                                       if (e.key === 'Enter') this.handleLogin()
                                   }}
                            />
                            <label className="form-label" htmlFor="form2Example2">Password</label>
                        </div>

                        <button type="button" className="btn btn-primary btn-block mb-4" onClick={() => this.handleLogin()}>
                            <div className="d-flex justify-content-center">
                                <div>
                                    Sign in
                                </div>
                                <div>
                                    { this.state.loggingIn ? <SpinnerElement size={25} /> : <></> }
                                </div>
                            </div>
                        </button>

                        <div className="text-center row mb-4">
                            <div className="col">
                                <a href="/forgot-password">Forgot password?</a>
                            </div>
                        </div>

                        <div className="text-center">
                            <p>Not a member? <a href="/register">Register</a></p>
                        </div>
                    </div>
                </div>
        )
    }
}

export default LoginRequired
