import {useParams} from "react-router-dom";
import ShowDailyElement from "../../../checkins/daily/ShowDailyElement";

const OtherDailyElement = (props) => {
    const {id, checkinId} = useParams();
    console.log(id);
    console.log(checkinId);
    const user = {
        id: id
    }
    return <ShowDailyElement user={user} id={checkinId} />
}

export default OtherDailyElement
