//import "./DocentenDashboardElement.module.css"
import React from "react";
import {Link} from "react-router-dom";
import {ImageService} from "../../index";
import {UserService} from "../../services/api/UserService";
import {GroupService} from "../../services/api/GroupService";
import { Chart, registerables } from 'chart.js';
import SpinnerElement from "../../loading/SpinnerElement";
Chart.register(...registerables);

class DocentenDashboardElement extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            groups: [],
            selectedGroup: +localStorage.getItem('DocentenDashboardElement.selectedGroup') ?? 0,
            from: this.startOfWeek().toISOString().split('T', 2)[0],
            to: this.endOfWeek().toISOString().split('T', 2)[0],
            groupsLoading: true,
            graphLoading: false,
        }
        this.chart = null;
        this.onResize = null;
    }

    componentWillUnmount() {
        if (this.state.selectedGroup > 0) localStorage.setItem('DocentenDashboardElement.selectedGroup', this.state.selectedGroup);
        window.removeEventListener('resize', this.onResize)
    }

    componentDidMount() {
        UserService.getGroups(this.props.user.id, {
            with: ["users", "users.notifications", "users.projects", "user_mood_avg", "hours_worked_today", "hours_worked_yesterday"]
        }).then(groups => {
            this.setState({
                groups: groups,
                groupsLoading: false,
            })
        })

        if (this.state.selectedGroup !== 0) this.updateHappiness(this.state.from, this.state.to)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedGroup !== this.state.selectedGroup && this.state.selectedGroup !== 0) this.updateHappiness(this.state.from, this.state.to)
    }

    updateHappiness(from, to) {
        this.setState({
            graphLoading: true,
        });
        GroupService.getHappiness(this.state.selectedGroup, from, to).then(happiness => {
            if (this.chart) this.chart.destroy()
            this.setChartAllUsers(happiness['happiness']);
            this.setState({
                graphLoading: false,
            })
        })
    }

    colorForName(str) {
        for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++) {
            h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
            h = h << 13 | h >>> 19;
        }

        const rand = function() {
            h = Math.imul(h ^ (h >>> 16), 2246822507);
            h = Math.imul(h ^ (h >>> 13), 3266489909);
            return ((h ^= h >>> 16) >>> 0) / 0xFFFFFFFF;
        }

        return `hsl(${Math.floor(rand() * 360)}, 100%, 50%)`;
    }

    setChartAllUsers(answersPoints) {
        const datasets = [];
        const labels = [];
        for (const username of Object.keys(answersPoints)) {

            if (Object.keys(answersPoints[username]).length !== 0) {
                datasets.push({
                    label: username,
                    data: answersPoints[username],
                    borderColor: this.colorForName(username),
                    backgroundColor: this.colorForName(username),
                    yAxisID: 'y',
                });

                for (const label of Object.keys(answersPoints[username])) {
                    if (labels.indexOf(label) === -1) {
                        labels.push(label);
                    }
                }
            }
        }

        // Because the labels are formatted 'yyyy-mm-dd', sorting by string value also sorts them by date.
        labels.sort();

        const ctx_allUsers = document.getElementById('chartAllUsers');
        this.chart = new Chart(ctx_allUsers, {
            type: 'line',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                        max: 5,
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            }
        });
        this.onResize = e => this.chart.resize();
        window.addEventListener('resize', this.onResize);
    }

    startOfWeek() {
        let date = new Date();
        const day = date.getDay() || 7;
        if (day !== 1) date.setHours(-24 * (day - 1));
        return date;
    }

    endOfWeek() {
        let date = this.startOfWeek();
        date.setDate(date.getDate() + 7)
        return date
    }

    handleHappinessFromSubmit(e) {
        e.preventDefault();
        this.updateHappiness(e.nativeEvent.target[0].value, e.nativeEvent.target[1].value)
    }

    render() {

        const selectedGroup = this.state.groups.filter(group => group.id === this.state.selectedGroup)[0] ?? {};
        const students = selectedGroup.users?.filter(user => user['roles'].includes('Student'));
        const averageUserScore = Math.round(selectedGroup['user_mood_avg'] ?? 3);
        const groepen = this.state.groups;
        const projectlessStudents = students?.filter(user => user.projects.length === 0).length;
        const hoursWorkedToday = selectedGroup['hours_worked_today'] ?? 0;
        const hoursWorkedYesterday = selectedGroup['hours_worked_yesterday'] ?? 0;
        let hoursDifPercentage = (hoursWorkedToday < hoursWorkedYesterday ? hoursWorkedToday / hoursWorkedYesterday : hoursWorkedYesterday / hoursWorkedToday) * 100
        if ((hoursWorkedToday === 0 && hoursWorkedYesterday > 0) || (hoursWorkedYesterday === 0 && hoursWorkedToday > 0)) hoursDifPercentage = '∞';
        hoursDifPercentage = Math.round(hoursDifPercentage);
        if (Number.isNaN(hoursDifPercentage)) hoursDifPercentage = 0;
        const totalUsers = students?.length ?? 0;
        const notifications = students?.map(user => user.notifications.map(noti => {
            return {...noti, user: user.name}
        })).flat() ?? [];

        return (
            <div className="row p-5 my-5 g-2 justify-content-center">
                <div className="col-6 rounded">
                    <div className="row g-2 h-25">
                        <div className="col-4">
                            <div className="p-3 bg-light h-100">
                                <h5 className="float-left text-muted">Tevredenheid</h5>
                                <div className="text-center d-flex justify-content-center">
                                    {
                                        this.state.groupsLoading ?
                                            <SpinnerElement size={50} /> :
                                            averageUserScore ? <img src={ ImageService.getImage("img" + averageUserScore + ".png") } alt={"Gemiddelde tevredenheid van " + averageUserScore }/> : "Geen score"
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="p-3 bg-light h-100">
                                <h5 className="float-left text-muted">Aantal studenten</h5>
                                <br/>
                                <h3 className="text-center d-flex justify-content-center"> {
                                    this.state.groupsLoading ?
                                        <SpinnerElement size={50} /> :
                                        totalUsers
                                } </h3>
                                {projectlessStudents > 0 ? <p className="text-center text-danger"> Waarvan {projectlessStudents} zonder project </p> : <></>}
                            </div>
                        </div>

                        <div className="col-6 rounded">
                            <div className="p-3 bg-light h-100">
                                <h5 className="text-muted">Uren Gewerkt</h5>

                                <h1 className="text-center d-flex justify-content-center">
                                    {
                                        this.state.groupsLoading ?
                                            <SpinnerElement size={50} /> :
                                            <>
                                                { hoursWorkedToday }
                                                <span className={"text-" + (hoursWorkedToday < hoursWorkedYesterday ? "danger" : "success") + " align-text-top fs-6"}>{ hoursDifPercentage }%</span>
                                            </>
                                    }
                                </h1>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="p-3 bg-light h-100">
                                Berichten({ notifications.length })
                                <Link type="button" className="btn btn-sm float-end btn-orange bi bi-arrow-right" to="/notifications"/>
                                <div>
                                    <table className="table table-sm">
                                        <thead>
                                        <tr>
                                            <th scope="col">Student</th>
                                            <th scope="col">Type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            notifications.map(noti =>
                                                <tr key={noti.id}>
                                                    <td>{ noti.user }</td>
                                                    <td>{ noti.data }</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12"/>
                        <div className="p-3 bg-light">
                            <div className="h1 display-6 bg-light">Tevredenheid</div>
                            <div className="w-100 position-relative">
                                <canvas hidden={this.state.graphLoading || this.state.groupsLoading} id="chartAllUsers"/>
                            </div>

                            <form id="happiness-form" hidden onSubmit={(e) => this.handleHappinessFromSubmit(e)} />

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="filter_from" className="form-label">Van</label>
                                    <input form="happiness-form" name="filter_from" type="date" className="form-control" id="filter_from" defaultValue={this.state.from}
                                           onChange={e => this.setState({from: e.target.value})}
                                    />
                                </div>

                                <div className="col">
                                    <label htmlFor="filter_to" className="form-label">Tot</label>
                                    <input form="happiness-form" name="filter_to" type="date" className="form-control" id="filter_to" defaultValue={this.state.to}
                                           onChange={e => this.setState({to: e.target.value})}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-4">
                                    <button form="happiness-form" type="submit" className="btn btn-orange btn-small">Filter toepassen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="col-12">
                        <div className="p-3 bg-light">
                            <div className="h1 display-6">Coachingsgroepen</div>
                            <div className="dropdown">
                                <select name="groep_id" id="groep_id" className="btn btn-orange" value={this.state.selectedGroup}
                                        onChange={e => {
                                            this.setState({selectedGroup: +e.target.value})
                                            localStorage.setItem('DocentenDashboardElement.selectedGroup', e.target.value)
                                        }}>
                                    <option value="0">Kies coachingsgroep</option>
                                    {
                                        groepen.map(groep =>
                                            <option key={groep.id} value={groep.id}>{ groep.name }</option>
                                        )
                                    }
                                </select>
                                <button className="btn btn-orange dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    Acties
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><Link className="dropdown-item" to="/profile/create">Gebruiker toevoegen</Link></li>
                                    <li><Link className="dropdown-item" to="/projects/create">Project aanmaken</Link></li>
                                    <li><Link className="dropdown-item" to="/projects">Project beheer</Link></li>
                                    <li><Link className="dropdown-item" to="/groups">Coachingsgroep beheer</Link></li>
                                </ul>
                            </div>
                        </div>

                        <table className="table table-responsive table-striped overflow-scroll">
                            <thead>
                            <tr>
                                <th scope="col">Gebruiker</th>
                                <th scope="col">Project</th>
                                <th scope="col">Acties</th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                this.state.groupsLoading ?
                                    <tr>
                                        <td className="text-center" colSpan="3">
                                            <div className="d-flex justify-content-center">
                                                <SpinnerElement size={40} />
                                            </div>
                                        </td>
                                    </tr> :
                                    students?.map(user =>
                                        <tr key={user.id}>
                                            <td>{ user.name }</td>
                                            <td>-</td>
                                            <td>
                                                <Link className="btn btn-orange" to={""+user.id}>
                                                    <i className="bi bi-eye"/>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default DocentenDashboardElement
