import React from "react";
import {useParams, Navigate} from "react-router-dom";
import {ProjectService} from "../services/api/ProjectService";
import {UserService} from "../services/api/UserService";
import {BreadcrumbContextSetter} from "../index";
import {NotFound} from "../error/NotFound";
import SpinnerElement from "../loading/SpinnerElement";

class EditProjectElementClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            project: {},
            name: '',
            description: '',
            members: [],
            redirect: false,
            usernameFilter: '',
            loading: true,
            loadingUsers: true,
            saving: false,
        }
        EditProjectElementClass.contextType = BreadcrumbContextSetter
    }

    componentDidMount() {
        this.context({
            'Projects': '/projects',
            'Edit': null
        })
        ProjectService.getProject(this.props.id, {
            with: ["users"]
        }).then(project => {
            this.setState({
                project: project,
                name: project.name,
                description: project.description,
                members: project.users.map(user => user.id),
                loading: false,
            })
        }).catch(res => {
            if (res.status === 404) {
                this.setState({
                    notFound: true
                })
            }
        })
        UserService.getUsers().then(users => {
            this.setState({
                users: users,
                loadingUsers: false,
            })
        })
    }

    removeMember(...ids) {
        const members = this.state.members
        ids.forEach(id => members.splice(members.indexOf(id), 1));
        this.setState({
            members: members
        })
    }

    addMember(...ids) {
        const members = this.state.members
        ids.forEach(id => members.push(id))
        this.setState({
            members: members
        })
    }

    handleSubmit(e) {
        this.setState({
            saving: true,
        })
        const body = {
            name: this.state.name,
            description: this.state.description,
            add: this.state.members.filter(member => !this.state.project.users.map(user => user.id).includes(member)),
            remove: this.state.project.users.map(user => user.id).filter(user => !this.state.members.includes(user)),
        };
        ProjectService.editProject(this.props.id, body).then(() => {
            this.setState({
                redirect: true,
                saving: false,
            })
        })
    }

    render() {
        if (this.state.notFound) return <NotFound/>
        if (this.state.redirect) return <Navigate to="/projects" />

        const users = this.state.users
        const projectMembers = this.state.project?.users ?? []

        return (
            <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8 text-center">
                        <h1 className="display-4 text-center" data-aos="fade-up">Project aanpassen</h1>
                        <div className={`row justify-content-center ${this.state.saving ? 'grayed-out' : ''}`}>
                            <div className="col-lg-10 col-sm-12 mt-3">
                                <label htmlFor="projectname">Project naam</label>
                                <input disabled={this.state.loading} name="name" id="projectname" type="text" className="form-control" value={this.state.name}
                                       onChange={e => this.setState({
                                           name: e.target.value
                                       })}
                                />
                            </div>

                            <div className="col-lg-10 col-sm-12 mt-3">
                                <label htmlFor="description">Beschrijving</label>
                                <input disabled={this.state.loading} name="description" id="description" type="text" className="form-control" value={this.state.description}
                                       onChange={e => this.setState({
                                           description: e.target.value
                                       })}
                                />
                            </div>

                            <div className="col-4 p-3 d-flex justify-content-center">
                                <button disabled={this.state.loading} className="btn btn-orange d-flex justify-content-center" onClick={e => this.handleSubmit(e)}>
                                    Opslaan
                                    {
                                        this.state.loading || this.state.saving ? <SpinnerElement size={25} /> : <></>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <h1 className="display-4 text-center" data-aos="fade-up">Huidige Leden</h1>
                            <div className="row justify-content-center">
                                <table className="table table-striped ">
                                    <thead>
                                    <tr>
                                        <th>Gebruikersnaam</th>
                                        <th>Email</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.loading ? <tr>
                                                <td colSpan="3">
                                                    <div className="d-flex justify-content-center">
                                                        <SpinnerElement size={50} />
                                                    </div>
                                                </td>
                                            </tr> :
                                        projectMembers.map(user =>
                                            <tr key={user.id}>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className="text-center mt-3">
                                <h1 className="display-4 text-center" data-aos="fade-up">Leden toevoegen</h1>
                                <div className="row justify-content-center">

                                    <table className="table table-striped ">
                                        <thead>
                                        <tr>
                                            <th>Selecteer</th>
                                            <th>Gebruikersnaam</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td />
                                            <td>
                                                <input className="text-center" type="text" placeholder="filter"
                                                       onChange={(e) => this.setState({
                                                           usernameFilter: e.target.value
                                                       })}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            this.state.loadingUsers ? <tr>
                                                    <td colSpan="3">
                                                        <div className="d-flex justify-content-center">
                                                            <SpinnerElement size={50} />
                                                        </div>
                                                    </td>
                                                </tr> :
                                            users.filter(user => user.name.toLowerCase().includes(this.state.usernameFilter.toLowerCase())).map(user =>
                                                <tr key={user.id}>
                                                    <td>
                                                        <input className="student-check" type="checkbox" defaultChecked={this.state.members.includes(user.id)}
                                                               onChange={e => e.target.checked ? this.addMember(user.id) : this.removeMember(user.id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {user.name}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    <button disabled={this.state.loading} id="submit-button"
                                            className={`d-flex justify-content-center btn btn-orange mb-5 ${this.state.saving ? 'grayed-out' : ''}`}
                                            onClick={e => this.handleSubmit(e)}>
                                        Opslaan
                                        {
                                            this.state.loading || this.state.saving ? <SpinnerElement size={25} /> : <></>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const EditProjectElement = (props) => {
    const { id } = useParams();
    return <EditProjectElementClass props id={id} />
}

export default EditProjectElement
