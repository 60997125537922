import React, {useState} from "react";
import styles from "./addDescription.module.css"

const AddDescription = (props) => {
    const [desc, setDesc] = useState("description")

    const handleSubmit = e => {
        setDesc(e.target.value)
        props.addDesc(desc)
    }

    return(
        <div>
            <h3>Voeg beschrijving toe</h3>
            <div className="form-control">
                <input type="text" className={styles.description_input} placeholder="Voeg beschrijving toe" onChange={handleSubmit} />
            </div>
        </div>
    )
}

export default AddDescription
