import DailyFormElement from "./DailyFormElement";
import {useParams, Navigate} from "react-router-dom";
import {UserService} from "../../services/api/UserService";
import React from 'react';
import {NotFound} from "../../error/NotFound";
import SpinnerElement from "../../loading/SpinnerElement";

class EditDailyElementClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkin: {},
            redirect: false,
            loading: true,
        }
    }

    componentDidMount() {
        UserService.getUser(this.props.user.id, {
            with: ['dailyCheckins']
        }).then(user => {
            const checkin = user['daily_checkins'].filter(daily => daily.id === +this.props.id)[0];
            if (checkin) this.setState({
                checkin: checkin,
                loading: false,
            });
            else this.setState({
                notFound: true,
            })
        }).catch(res => {
            if (res.status === 404) {
                this.setState({
                    notFound: true,
                })
            }
        })
    }

    handleSubmit(e, score, desc, hours, comment) {
        UserService.updateCheckin(this.props.user.id, this.props.id, 'daily', {
            mood_score: score,
            mood_description: desc,
            hours_worked: hours,
            comment: comment
        }).then(() => this.setState({redirect: true})).catch(res => {
            this.setState({
                loading: false,
            });
            alert(`Er is helaas iets fout gegaan. (${res.status})`)
        });
    }

    render() {
        if (this.state.notFound) return <NotFound/>
        return (
            <div className="row justify-content-center align-items-center mt-5">
                {this.state.redirect ? <Navigate to="/checkins" /> : <></>}
                <div className="col-lg-6 col-md-8 col-sm-10 text-center shadow mb-5 bg-body rounded-3">
                    <div className="row bg-orange text-white p-3 rounded-top">
                        <h1 className="display-4">Daily Check-in</h1>
                    </div>

                    {
                        this.state.loading ?
                            <div className="d-flex justify-content-center m-5">
                                <SpinnerElement size={80}/>
                            </div> :
                            Object.keys(this.state.checkin).length ?
                                <DailyFormElement editable={true} data={this.state.checkin} handleSubmit={(e, score, desc, hours, comment) => this.handleSubmit(e, score, desc, hours, comment)} /> :
                                <></>
                    }

                </div>
            </div>
        )
    }
}

const EditDailyElement = (props) => {
    const { id } = useParams();
    return <EditDailyElementClass {...props} id={id} />
}

export default EditDailyElement
