import {EventService} from "../../index";
import {flushSync} from "react-dom";

class UserEventService {

    static events = [
        '.UserUpdated',
        '.App\\Events\\User\\Jobrole\\UserJobroleAttached',
        '.App\\Events\\User\\Jobrole\\UserJobroleDetached',
        '.App\\Events\\User\\Project\\UserProjectAttached',
        '.App\\Events\\User\\Project\\UserProjectDetached',
    ]

    static subscribe(event, identifier, callback) {
        EventService.subscribe('users', event, identifier, callback)
    }

    static subscribeWithId(id, event, identifier, callback) {
        EventService.subscribe(`users.${id}`, event, identifier, callback)
    }

    static handleStateWithId(component, id, wrap) {
        this.subscribeWithId(id, '.UserUpdated', component.constructor.name,(user) => {
            const changedState = wrap ?
                {
                    [wrap]: {...component.state[wrap], ...user}
                }         : {...user};
            flushSync(component.setState(changedState))
        });
        if (EventService.stateHasField(component.state, 'jobroles', wrap)) {
            this.subscribeWithId(id, '.App\\Events\\User\\Jobrole\\UserJobroleAttached', component.constructor.name,(jobroles) => {
                const changedState = EventService.makeAttachedStateChanged(component.state, 'jobroles', jobroles, wrap)
                flushSync(component.setState(changedState))
            })
            this.subscribeWithId(id, '.App\\Events\\User\\Jobrole\\UserJobroleDetached', component.constructor.name,(jobroleIds) => {
                const changedState = EventService.makeDetachedStateChanged(component.state, 'jobroles', jobroleIds, wrap)
                flushSync(component.setState(changedState))
            })
        }
        if (EventService.stateHasField(component.state, 'projects', wrap)) {
            this.subscribeWithId(id, '.App\\Events\\User\\Project\\UserProjectAttached', component.constructor.name,(projects) => {
                flushSync(component.setState(EventService.makeAttachedStateChanged(component.state, 'projects', projects, wrap)))
            })
            this.subscribeWithId(id, '.App\\Events\\User\\Project\\UserProjectDetached', component.constructor.name,(projectIds) => {
                flushSync(component.setState(EventService.makeDetachedStateChanged(component.state, 'projects', projectIds, wrap)))
            })
        }
    }

    static unsubscribe(component) {
        this.events.forEach((event) => EventService.unsubscribe('users', event, component.constructor.name))
    }

    static unsubscribeWithId(id, component) {
        this.events.forEach((event) => EventService.unsubscribe(`users.${id}`, event, component.constructor.name))
    }
}

export default UserEventService
