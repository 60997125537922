import React from "react";
import {Link} from "react-router-dom";
import CheckinButtonsElement from "./CheckinButtonsElement";
import {UserService} from "../../services/api/UserService";
import {Chart} from "chart.js";
import SpinnerElement from "../../loading/SpinnerElement";

class StudentenDashboardElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            canDoDaily: false,
            canDoWeekly: false,
            loading: true,
        }
        this.happinessChart = null;
        this.hoursChart = null;
        this.onResize1 = null;
        this.onResize2 = null;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize1)
        window.removeEventListener('resize', this.onResize2)
    }

    componentDidMount() {
        UserService.getUser(this.props.user.id, {
            with: ['canDoDaily', 'canDoWeekly', 'happiness', 'hoursWorkedThisWeek']
        }).then(user => {
            this.setState({
                loading: false,
                canDoDaily: user['can_do_daily'],
                canDoWeekly: user['can_do_weekly'],
            })
            this.setGraphDashboard(user['happiness'])
            this.setHoursGraph(user['hours_worked_this_week'])
        })
    }

    setGraphDashboard(happiness) {

        const labelData = Object.keys(happiness);
        const graphData = Object.values(happiness);

        const ctx = document.getElementById('myChart');
        this.happinessChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labelData,
                datasets: [{
                    label: 'Hoe voel je je vandaag?',
                    data: graphData,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.3,
                }]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                        max: 5,
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            }
        });
        this.onResize1 = e => this.happinessChart.resize()
        window.addEventListener('resize', this.onResize1)
    }

    setHoursGraph(hoursPoints) {
        const ctx = document.getElementById('hoursChart');
        this.hoursChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"],
                datasets: [{
                    data: hoursPoints,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 205, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(201, 203, 207, 0.2)'
                    ],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(201, 203, 207)'
                    ],
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                        max: 10,
                    }
                }
            }
        });
        this.onResize2 = e => this.hoursChart.resize()
        window.addEventListener('resize', this.onResize2)
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <h1 className="display-4 text-center" data-aos="fade-up">Dashboard Daily/Weekly Check-in</h1>
                    <p className="display-6 text-center p-5" data-aos="fade-up" data-aos-delay="200">
                        Welkom, { this.props.user.name }</p>

                    <div className="col-lg-5 col-sm-12 p-3 text-center p-md-2 shadow p-md-3 mb-5 bg-body rounded"
                         data-aos="fade-right" data-aos-delay="300">
                        <p className="fs-3">Overzicht</p>
                        <div hidden={this.state.loading}>
                            <canvas id="myChart"/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 p-3 text-center p-md-2 shadow p-md-3 mb-5 bg-body rounded"
                         data-aos="fade-right" data-aos-delay="300">
                        <p className="fs-3">Uren registratie</p>
                        <div hidden={this.state.loading}>
                            <canvas id="hoursChart"/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 p-sm-0 p-md-0 shadow p-md-3 mb-5 bg-body rounded offset-md-1 text-center"
                         data-aos="fade-left" data-aos-delay="300">
                        <CheckinButtonsElement canDoDaily={this.state.canDoDaily} canDoWeekly={this.state.canDoWeekly} />
                        <Link className="btn btn-orange" to="/checkins">
                            Check-in antwoorden
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default StudentenDashboardElement
