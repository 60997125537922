import {EventService} from "../../index";

class ProjectEventService {

    static events = [
        '.ProjectCreated',
        '.ProjectDeleted',
        '.ProjectUpdated',
    ]

    static subscribe(event, identifier, callback) {
        EventService.subscribe('projects', event, identifier, callback)
    }

    static handleState(component) {
        this.subscribe('.ProjectCreated', component.constructor.name, (project) => {
            component.setState({
                projects: [...component.state.projects, project]
            })
        });
        this.subscribe('.ProjectDeleted', component.constructor.name, (project) => {
            component.setState({
                projects: component.state.projects.filter(stateProject => stateProject.id !== project.id)
            })
        });
        this.subscribe('.ProjectUpdated', component.constructor.name, (project) => {
            component.setState({
                projects: [
                    ...component.state.projects.filter(stateProject => stateProject.id !== project.id),
                    ...component.state.projects.filter(stateProject => stateProject.id === project.id).map(() => project),
                ]
            })
        });
    }

    static unsubscribe(component) {
        this.events.forEach((event) => EventService.unsubscribe('projects', event, component.constructor.name))
    }
}

export default ProjectEventService
