import {Link, Navigate} from "react-router-dom";
import React from 'react';
import {BreadcrumbContextSetter, ImageService} from "../index";
import {UserService} from "../services/api/UserService";
import SpinnerElement from "../loading/SpinnerElement";

class CreateProfileElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            role: '',
            redirect: false,
            loading: false,
        }
        CreateProfileElement.contextType = BreadcrumbContextSetter
    }

    componentDidMount() {
        this.context({
            'Profile': '/profile',
            'Create': '/profile/create'
        })
    }

    handleSubmit(e) {
        e.preventDefault()
        this.setState({loading: true})
        const target = e.nativeEvent.target;
        const data = {
            name: target[0].value,
            email: target[1].value,
            role: target[2].value,
        }
        UserService.addUser(data).then(() => this.setState({redirect: true}))
    }

    render() {

        const userType = this.props.user?.roles[0] ?? 'User';
        const roles = [
            {
                'id': 1,
                'name': 'Student'
            },
            {
                'id': 2,
                'name': 'Docent'
            }
        ];

        return (
            <div className="container mt-5">
                {this.state.redirect ? <Navigate to="/dashboard" /> : <></>}
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card px-2 py-5 shadow p-3 mb-5 bg-body rounded" data-aos="fade-up" data-aos-delay="300">
                            <div className="text-center">
                                <img src={ImageService.getImage('thumbnail.png')} className="rounded-circle w-25" alt="Profile" />
                            </div>
                            <div className="text-center mt-3">
                                <span className="bg-secondary p-1 px-4 rounded text-white">{userType}</span>
                                <br />
                                <br />
                                <Link to="/dashboard">Terug naar het dashboard</Link>

                                <div className={`row justify-content-center${this.state.loading ? ' grayed-out' : ''}`}>
                                    <form id="role-form" onSubmit={(e) => this.handleSubmit(e)} />
                                    <div className="col-lg-10 col-sm-12 mt-3">
                                        <label htmlFor="name">Naam</label>
                                        <input form="role-form" name="name" id="name" type="text" className="form-control" placeholder="Naam" />
                                    </div>

                                    <div className="col-lg-10 col-sm-12 mt-3">
                                        <label htmlFor="email">E-mail</label>
                                        <input form="role-form" name="email" id="email" type="text" className="form-control" placeholder="E-mail" />
                                    </div>

                                    <div className="form-check-input-border">
                                        <label htmlFor="roles" className="mt-1">Rol</label>
                                        <br/>
                                        <select form="role-form" id="roles" name='roles'>
                                            <option value="0">Kies een rol</option>
                                            {
                                                roles.map(role =>
                                                    <option key={role.id} value={role.id}>{role.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-4 p-3 d-flex justify-content-center">
                                        <button form="role-form" type="submit" className="btn btn-orange d-flex align-items-center">
                                            Opslaan
                                            {this.state.loading ? <SpinnerElement size={25} /> : <></> }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateProfileElement
