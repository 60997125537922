import React from "react";
import {Link} from "react-router-dom";
import {BreadcrumbContextSetter, ImageService} from "../index";
import {UserService} from "../services/api/UserService";
import {JobroleService} from "../services/api/JobroleService";
import SpinnerElement from "../loading/SpinnerElement";
import JobroleEventService from "../services/events/JobroleEventService";
import UserEventService from "../services/events/UserEventService";

class ProfileElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                jobroles: [],
                projects: [],
            },
            jobroles: [],
            addingJobroles: [],
            removingJobroles: [],
            jobroleLoading: true,
            userLoading: true
        };
        ProfileElement.contextType = BreadcrumbContextSetter;
    }

    removeUserJobrole(id) {
        this.setState({
            removingJobroles: [...this.state.removingJobroles, id]
        })
        UserService.removeJobrole(this.props.user.id, id).then(() => this.setState({
            user: {
                ...this.state.user,
                jobroles: this.state.user.jobroles.filter(jobrole => jobrole.id !== id)
            }
        }));
    }

    addUserJobrole(id) {
        if (id > 0) {
            this.setState({
                addingJobroles: [...this.state.addingJobroles, id]
            })
            UserService.addJobrole(this.props.user.id, id).then(() => {
                UserService.getUser(this.props.user.id, {
                    with: ['jobroles']
                }).then(user => {
                    this.setState({
                        user: {
                            ...this.state.user,
                            jobroles: user.jobroles,
                        }
                    })
                })
            });
        }
    }

    handleAddJobroleEvent(e) {
        e.preventDefault()
        this.addUserJobrole(+e.nativeEvent.target[0].value)
    }

    registerListeners(id) {
        JobroleEventService.handleState(this, 'jobroles')
        UserEventService.handleStateWithId(this, id, 'user')
    }

    componentWillUnmount() {
        UserEventService.unsubscribeWithId(this.props.user.id, this)
        JobroleEventService.unsubscribe(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.user.jobroles.length > this.state.user.jobroles.length) {
            this.setState({
                removingJobroles: this.state.removingJobroles.filter(jobroleToRemove => this.state.user.jobroles.map(jobrole => jobrole.id).includes(jobroleToRemove)),
            })
        } else if (prevState.user.jobroles.length < this.state.user.jobroles.length) {
            this.setState({
                addingJobroles: this.state.addingJobroles.filter(jobroleToAdd => !this.state.user.jobroles.map(jobrole => jobrole.id).includes(jobroleToAdd)),
            })
        }
    }

    componentDidMount() {

        this.context({
            'Profile': '/profile'
        });

        JobroleService.getJobroles().then(json => {
            this.setState({
                jobroles: json,
                jobroleLoading: false,
            });
        });
        UserService.getUser(this.props.user.id, {
            with: ['jobroles', 'projects']
        }).then(json => {
            this.setState({
                user: {...this.state.user, jobroles: json.jobroles, projects: json.projects},
                userLoading: false,
            })
        });

        this.registerListeners(this.props.user.id)
    }

    render() {

        const user = this.props.user
        const userType = user.roles[0] ?? 'User';
        const username = user.name;
        const email = user.email;
        const averageUserScore = 3;

        return (
            <div className="" style={{
                width: "auto",
                marginRight: "0px",
                marginLeft: "0px"
            }}>
                <div className="row" style={{
                    marginRight: "0px",
                    marginLeft: "0px"
                }}>
                    <div className="col-lg-3 col-xxl-2" style={{
                        margin: "7px -30px 7px 7px"
                    }}>
                        <div className="card">
                            <div className="card-body text-center">
                                <img src={ ImageService.getImage('img4.png') } className="rounded-circle " alt="Profile"/>
                                <br/>
                                <span className="bg-secondary p-1 px-4 rounded text-white">
                            { userType }
                        </span>
                            </div>
                            <Link className="btn btn-success" to={`${this.props.user.id}/edit`}>
                                <i className="bi bi-pencil"/>
                            </Link>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5><i className="bi bi-person-lines-fill"> { username } </i></h5>
                                <h5><i className="bi bi-envelope-fill"> </i> { email } </h5>
                                <h5><i className="bi bi-dot"/></h5>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="p-3  h-100">
                                    <h5 className="text-center">Tevredenheid</h5>
                                    <div className="text-center">
                                        <img src={ImageService.getImage('img' + averageUserScore + '.png')} alt="Tevredenheid"/>
                                        <Link className="btn btn-orange mt-4" to="/checkins">Bekijk je check-ins</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-xl-10">
                        <div className="row" style={{
                            marginRight: "0px",
                            marginLeft: "0px"
                        }}>
                            <div className="col-xl-12">
                                <div className="card" style={{
                                    marginBottom: "7px",
                                    marginTop: "7px",
                                    padding: "7px"
                                }}>
                                    <div style={{
                                        padding: "1px",
                                        margin: "6px 6px 6px -1px"
                                    }}>
                                        <div className="row" style={{
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            width: "auto"
                                        }}>
                                            <div className="col-md-6">
                                                <div className="card">
                                                    <div className={this.state.userLoading ? 'grayed-out' : ''}>
                                                        <div className="row justify-content-center">
                                                            <h2 className="d-flex align-items-baseline">
                                                                Huidige projecten
                                                                { this.state.userLoading ? <SpinnerElement size={25} /> : <></> }
                                                            </h2>
                                                        </div>

                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Project</th>
                                                                    <th>Beschrijving</th>
                                                                    <th/>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.user.projects?.map(project =>
                                                                    <tr key={project.id}>
                                                                        <td>
                                                                            { project.name }
                                                                        </td>
                                                                        <td>
                                                                            { project.description }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{
                                                marginLeft: "0px"
                                            }}>
                                                <div className="card" style={{
                                                    marginRight: "0px"
                                                }}>
                                                    <div className={this.state.addingJobroles.length || this.state.jobroleLoading || this.state.userLoading ? 'grayed-out' : ''} style={{
                                                        marginRight: "0px",
                                                        marginLeft: "0px"
                                                    }}>
                                                        <h2 className="d-flex align-items-baseline">
                                                            Beroepsrollen
                                                            { this.state.addingJobroles.length || this.state.jobroleLoading || this.state.userLoading ? <SpinnerElement size={25} /> : <></> }
                                                        </h2>

                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                    <th><label htmlFor="jobrole-select">Rol</label></th>
                                                                    <th>
                                                                        <form id="add-jobrole-form" onSubmit={(e) => this.handleAddJobroleEvent(e)}>
                                                                            <select form="add-jobrole-form" name="jobrole" id="jobrole-select">
                                                                                <option value="0">Kies beroepsrol</option>
                                                                                {
                                                                                    this.state.jobroles.filter(jobrole => !this.state.user.jobroles?.map(jr => jr.id)?.includes(jobrole.id))
                                                                                        .map(jobrole => <option key={jobrole.id} value={jobrole.id}>{jobrole.name}</option>)
                                                                                }
                                                                            </select>
                                                                        </form>
                                                                    </th>
                                                                    <th>
                                                                        <button form="add-jobrole-form" type="submit" className="bi bi-plus" />
                                                                    </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            { this.state.user.jobroles?.map(jobrole =>
                                                                <tr key={jobrole.id} className={this.state.removingJobroles.includes(jobrole.id) ? 'grayed-out' : ''}>
                                                                    <td>
                                                                        <a href={jobrole.href} target="_blank" rel="noreferrer"> { jobrole.name } </a>
                                                                    </td>
                                                                    <td/>
                                                                    <td>
                                                                        <button className="btn btn-danger" onClick={e => this.removeUserJobrole(jobrole.id)}>
                                                                            {
                                                                                this.state.removingJobroles.includes(jobrole.id) ?
                                                                                    <SpinnerElement size={25} /> :
                                                                                    <i className="bi bi-trash"/>
                                                                            }
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        width: "auto"
                                    }}>
                                        <div className="col-xl-12 offset-xl-0">
                                            <div className="card" style={{
                                                marginRight: "0px"
                                            }}>
                                                <div className="card-body">
                                                    <h2>HBO-I Competenties</h2>
                                                </div>

                                                <label htmlFor="architectuurlaag">Kies Architectuurlaag:</label>

                                                <select name="cars" id="cars">
                                                    <option value="gebruikersinteractie">Gebruikersinteractie</option>
                                                    <option value="organisatieprocessen">Organisatieprocessen</option>
                                                    <option value="infrsastructuur">Infrastructuur</option>
                                                    <option value="software">Software</option>
                                                    <option value="hardwareinterfacing">Hardwareinterfacing</option>
                                                </select>
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Competentie</th>
                                                        <th>Niveau</th>
                                                        <th>Doel</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            Analyseren
                                                        </td>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Adviseren
                                                        </td>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Ontwerpen
                                                        </td>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Realiseren
                                                        </td>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Manage&Control
                                                        </td>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileElement
