import React, {useEffect, useState} from "react";
import styles from "./addFile.module.css"
import UploadedFilesList from "./uploadedFilesList";

const AddFile = (props) => {
    const [inputState, setInputState] = useState("file")

    const handleChange = e => {
        let inputValue = document.getElementById("file-input");
        let title = ""
        if (inputState === "text"){
            title = inputValue.value.match(/[^\\/]*$/);
            props.addFile({name: title, file: inputValue.files[0], type: "file"});
        } else {
            title = inputValue.value
            props.addFile({name: title, file: title, type: "link"});
        }
    }

    const textInput = <input type="file" className="form-control"  id="file-input" aria-describedby="inputGroupFileAddon03" aria-label="Upload" />
    const fileInput = <input type="text" className="form-control" id="file-input" placeholder={"vul hier een link in"} />

    const [input, setInput] = useState(fileInput);

    useEffect(() => {
        console.log(props.files)
    })



    const handleClick = () => {
        if (inputState === "file"){
            setInput(textInput)
            setInputState("text")
            document.getElementById("switcher").innerHTML = "Link"
        } else if (inputState === "text") {
            setInput(fileInput)
            setInputState("file")
            document.getElementById("switcher").innerHTML = "Bestand"
        }

    }


    return (
        <div className={styles.select_container}>
            <h3>Voeg bestanden of links toe</h3>
            <div className="form-control">
                <div className="input-group mb-3">
                    <button className="btn btn-outline-secondary" type="button"
                            id="switcher" onClick={handleClick}>Bestand
                    </button>
                     {input}
                    <button className="btn btn-success" onClick={handleChange}>Voeg toe</button>
                </div>
                <UploadedFilesList delFile={props.delFile} files={props.files} />
            </div>
        </div>
    )
}

export default AddFile
