import './WeeklyFormElement.css'
import React from "react";
import SpinnerElement from "../../loading/SpinnerElement";

class WeeklyFormElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            thingsLearned: this.props.data['things_learned'],
            thingsDone: this.props.data['things_done'],
            comment: this.props.data.comment,
            saving: false,
        }
    }

    handleClick(e) {
        this.setState({
            saving: true,
        })
        this.props.handleSubmit(e, this.state.thingsLearned, this.state.thingsDone, this.state.comment);
    }

    render() {

        return (
            <div className={`row justify-content-center mb-5 ${this.state.saving ? 'grayed-out' : ''}`}>
                <div className="col-lg-10 col-sm-12">
                    <label htmlFor="things_learned">Wat heb je afgelopen week geleerd?</label>
                    <textarea
                        readOnly={!this.props.editable}
                        name="things_learned"
                        id="things_learned"
                        placeholder="Vul hier in wat je de afgelopen week geleerd hebt."
                        className="form-control"
                        value={this.state.thingsLearned}
                        onChange={e => this.setState({
                            thingsLearned: e.target.value,
                        })}
                    />
                </div>

                <div className="col-lg-10 col-sm-12">
                    <label htmlFor="things_done">Wat heb je afgelopen week gedaan?</label>
                    <textarea
                        readOnly={!this.props.editable}
                        name="things_done"
                        id="things_done"
                        placeholder="Vul hier in wat je de afgelopen week gedaan hebt."
                        className="form-control"
                        value={this.state.thingsDone}
                        onChange={e => this.setState({
                            thingsDone: e.target.value,
                        })}
                    />
                </div>

                <div className="col-lg-10 col-sm-12">
                    <label htmlFor="comment">Opmerkingen</label>
                    <textarea
                        readOnly={!this.props.editable}
                        name="comment"
                        id="comment"
                        placeholder="Ruimte voor eventuele opmerkingen."
                        className="form-control"
                        value={this.state.comment}
                        onChange={e => this.setState({
                            comment: e.target.value,
                        })}
                    />
                </div>
                { this.props.editable ?
                    <div className="col-4 p-3">
                        <button className="btn btn-orange" onClick={(e) => this.handleClick(e)}>
                            Opslaan
                            { this.state.saving ? <div className="float-end">
                                <SpinnerElement size={25} />
                            </div>: <></> }
                        </button>
                    </div> : <></>
                }
            </div>
        )
    }
}

export default WeeklyFormElement
