export class ImageService {

    constructor(appUrl) {
        this.appUrl = appUrl;
    }

    getImage(fileName) {
        return this.appUrl + '/img/' + fileName;
    }
}
