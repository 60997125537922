import './LoadingScreen.css'
import SpinnerElement from "./SpinnerElement";

const LoadingScreen = ({ transparent }) => {
    return (
        <div id="loading-screen" className={`d-flex justify-content-center align-items-center h-100 w-100 bg-secondary${transparent ? ' transparent': ''}`}>
            <div style={{
                width: "100px",
                height: "100px"
            }}>
                <SpinnerElement size={100} />
            </div>
        </div>
    )
}

export default LoadingScreen
