import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.js';
import React, {createContext} from "react";
import {createRoot} from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import "./app.css"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import UploadContainer from "./uploadElement/uploadContainer";
import ProfileElement from "./profile/ProfileElement";
import DashboardElement from "./dashboard/DashboardElement";
import StudentDetailsElement from "./dashboard/docent/StudentDetailsElement";
import CreateProfileElement from "./profile/CreateProfileElement";
import CreateProjectElement from "./projects/CreateProjectElement";
import IndexProjectElement from "./projects/IndexProjectElement";
import EditProjectElement from "./projects/EditProjectElement";
import IndexGroupElement from "./groups/IndexGroupElement";
import IndexNotificationElement from "./notifications/IndexNotificationElement";
import ShowGroupElement from "./groups/ShowGroupElement";
import EditGroupElement from "./groups/EditGroupElement";
import CreateGroupElement from "./groups/CreateGroupElement";
import EditProfileElement from "./profile/EditProfileElement";
import IndexCheckinElement from "./checkins/IndexCheckinElement";
import CreateDailyElement from "./checkins/daily/CreateDailyElement";
import ShowDailyElement from "./checkins/daily/ShowDailyElement";
import EditDailyElement from "./checkins/daily/EditDailyElement";
import CreateWeeklyElement from "./checkins/weekly/CreateWeeklyElement";
import ShowWeeklyElement from "./checkins/weekly/ShowWeeklyElement";
import EditWeeklyElement from "./checkins/weekly/EditWeeklyElement";
import LoginRequired from "./loginElement/LoginRequired";
import {ApiService} from "./services/api/ApiService";
import {ImageService as _ImageService} from './services/ImageService';
import Pusher from 'pusher-js';
import LogoutElement from "./loginElement/LogoutElement";
import { LayoutElement } from "./LayoutElement";
import _EventService from "./services/events/EventService";
import {ConditionalAccessDenied} from "./error/ConditionalAccessDenied";
import OtherDailyElement from "./dashboard/docent/checkins/OtherDailyElement";
import OtherWeeklyElement from "./dashboard/docent/checkins/OtherWeeklyElement";
import {NotFound} from "./error/NotFound";

const appUrl = 'https://api.checkintool.opendidact.nl';

export const UserContext = createContext({});
export const UserContextSetter = createContext(() => {});
export const BreadcrumbContextSetter = React.createContext({});

export const Api = new ApiService(appUrl);
export const ImageService = new _ImageService(appUrl);

const root = createRoot(document.getElementById("root"));

window.Pusher = Pusher;

export const EventService = new _EventService({
    broadcaster: 'pusher',
    key: '73c0fd117629d0f0a6ed',
    cluster: 'eu',
    forceTLS: false,
    authEndpoint: appUrl + '/broadcasting/auth',
});

export const EventListener = EventService.connection

    root.render(
    <LoginRequired>
        <UserContext.Consumer>
            {user => (
                <BrowserRouter>
                    <Routes>
                        <Route path="/logout" element={<LogoutElement />}/>
                        <Route path="/upload" element={<UploadContainer />}/>
                        <Route path="" element={<LayoutElement />}>
                            <Route path="profile" element={<ProfileElement user={user} />}/>
                            <Route path="profile/*">
                                <Route path="create" element={<ConditionalAccessDenied condition={() => user.permissions?.includes('manage users')} />}>
                                    <Route index element={<CreateProfileElement user={user} />} />
                                </Route>
                                <Route path=":id" element={<ConditionalAccessDenied condition={(id) => user.permissions?.includes('manage users') || user.id === +id} />}>
                                    <Route path="edit" element={<EditProfileElement user={user} />}/>
                                </Route>
                            </Route>
                            <Route path="" element={<Navigate to="/dashboard" />}/>
                            <Route path="dashboard" element={<DashboardElement user={user} />}/>
                            <Route path="dashboard/*" element={<ConditionalAccessDenied condition={(id) => user.permissions?.includes('manage users') || user.id === +id} />}>
                                <Route path=":id" element={<StudentDetailsElement user={user} />} />
                                <Route path=":id/checkins/daily/:checkinId" element={<OtherDailyElement user={user} />} />
                                <Route path=":id/checkins/weekly/:checkinId" element={<OtherWeeklyElement user={user} />} />
                            </Route>
                            <Route path="projects" element={<IndexProjectElement user={user} />} />
                            <Route path="projects/*" element={<ConditionalAccessDenied condition={() => user.permissions?.includes('manage projects')} />}>
                                <Route path="create" element={<CreateProjectElement user={user} />} />
                                <Route path=":id/edit" element={<EditProjectElement user={user} />} />
                            </Route>
                            <Route path="groups/*" element={<ConditionalAccessDenied condition={() => user.permissions?.includes('manage groups')} />} >
                                <Route index element={<IndexGroupElement user={user} />} />
                                <Route path="create" element={<CreateGroupElement user={user} />} />
                                <Route path=":id" element={<ShowGroupElement user={user} />} />
                                <Route path=":id/edit" element={<EditGroupElement user={user} />} />
                            </Route>
                            <Route path="notifications" element={<IndexNotificationElement user={user} />} />
                            <Route path="checkins" element={<IndexCheckinElement user={user} />} />
                            <Route path="checkins/daily/create" element={<CreateDailyElement user={user} />} />
                            <Route path="checkins/daily/:checkinId" element={<ShowDailyElement user={user} />} />
                            <Route path="checkins/daily/:id/edit" element={<EditDailyElement user={user} />} />
                            <Route path="checkins/weekly/create" element={<CreateWeeklyElement user={user} />} />
                            <Route path="checkins/weekly/:checkinId" element={<ShowWeeklyElement user={user} />} />
                            <Route path="checkins/weekly/:id/edit" element={<EditWeeklyElement user={user} />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                )
            }
        </UserContext.Consumer>
    </LoginRequired>
)

serviceWorkerRegistration.register();
