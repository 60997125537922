import React from 'react';
import {UserService} from "../services/api/UserService";
import {BreadcrumbContextSetter} from "../index";
import SpinnerElement from "../loading/SpinnerElement";

class IndexNotificationElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            loading: true,
            removingNotifications: [],
        }
        IndexNotificationElement.contextType = BreadcrumbContextSetter
    }

    componentDidMount() {
        this.context({
            'Notifications': '/notifications'
        })
        UserService.getUser(this.props.user.id, {
            with: ['notifications']
        }).then(user => {
            this.setState({
                loading: false,
                notifications: user['notifications']
            })
        })
    }

    handleDelete(e, id) {
        this.setState({
            removingNotifications: [...this.state.removingNotifications, id],
        })
        UserService.removeNotification(this.props.user.id, id).then(() => {
            this.setState({
                notifications: this.state.notifications.filter(noti => noti.id !== id),
                removingNotifications: this.state.removingNotifications.filter(notiId => notiId !== id),
            })
        });
    }

    render() {

        const notifications = this.state.notifications;

        return (
            <div className="card px-2 py-5 shadow p-3 mb-5 bg-body rounded">
                <div className="row text-center">
                    <h1>Meldingen ({notifications.length})</h1>
                </div>
                <div className="row mt-5 justify-content-center">
                    <div className="col-8 justify-content-center">
                        <table className="table table-striped table-responsive table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Opmerking</th>
                                <th scope="col">Acties</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.loading ? <tr>
                                        <td colSpan="3">
                                            <div className="d-flex justify-content-center">
                                                <SpinnerElement size={50} />
                                            </div>
                                        </td>
                                    </tr> :
                                notifications.map(notification => {
                                    const isDeleting = this.state.removingNotifications.includes(notification.id);
                                    return <tr key={notification.id} className={isDeleting ? 'grayed-out' : ''} >
                                            <td>{notification.type}</td>
                                            <td>{notification.data}</td>
                                            <td>
                                                <button className="btn btn-success" onClick={e => this.handleDelete(e, notification.id)}>
                                                    {
                                                        isDeleting ? <SpinnerElement size={25} /> : <i className="bi bi-check"/>
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default IndexNotificationElement
