export class ApiService {

    constructor(appUrl) {
        this.appUrl = appUrl;
        this.apiUrl = appUrl + '/api/v3'
    }

    headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }

    call(url, method = 'GET', body = {}, headers = {}) {

        if (url.startsWith('/')) url = this.apiUrl + url;

        if (method === 'GET' && body && Object.keys(body).length > 0) {
            method = 'POST'
            url += '/search'
        }

        headers = {
            ...this.headers,
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            ...headers
        }

        let init = {
            method: method,
            headers: headers
        }

        if (method !== 'GET') init = {
            ...init,
            body: JSON.stringify(body),
        }

        return fetch(url, init).then(res => res.status >= 200 && res.status < 300 ? res.headers.get('Content-Type') === 'application/json' ? res.json() : {} : Promise.reject(res))
    }

    authenticate(email, password) {
        return fetch(
            this.appUrl + "/api/sanctum/token",
            {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    password: password,
                    'device': 'react'
                }),
                headers: this.headers
            }
        ).then(res => res.status >= 200 && res.status < 300 ? res.text() : Promise.resolve(null))
    }

    fileUpload(formData) {
        return fetch(
            this.apiUrl + '/uploading-file-api',
            {
                method: 'POST',
                body: formData,
                mode: 'no-cors',
            }
        ).then((response) => response.json())
    }
}
