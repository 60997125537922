import React, {useState} from "react";
import styles from "./uploadedFile.module.css"
import { RxCross2 } from "react-icons/rx"

const UploadedFile = (props) => {
    return (
            <div className={styles.uploadedFileContainer}>
                <div className={"form-control"}>
                    {props.file.name}
                    <div onClick={() => {props.delFile(props.file.id)}} className={styles.deleteButton}><RxCross2 /></div>
                </div>
            </div>
    )
}

export default UploadedFile
