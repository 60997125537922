import {Api} from "../index";

const UploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file.file);
    console.log(file)
    // formData.append('Name', file.name)

    Api.fileUpload(formData)
        .then((result) => {
            console.log('Success:', result);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};

export default UploadFile


