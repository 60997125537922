import {useParams} from "react-router-dom";
import React from "react";
import {GroupService} from "../services/api/GroupService";
import {BreadcrumbContextSetter} from "../index";
import {NotFound} from "../error/NotFound";
import SpinnerElement from "../loading/SpinnerElement";

class ShowGroupElementClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            group: {},
            loading: true,
        }
        ShowGroupElementClass.contextType = BreadcrumbContextSetter
    }

    updateContext() {
        this.context({
            'Groups': '/groups',
            [this.state.group.name ?? '']: `/groups/${this.props.id}`
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.group.name !== prevState.group.name) this.updateContext()
    }

    componentDidMount() {
        this.updateContext()
        GroupService.getGroup(this.props.id, {
            with: ['users']
        }).then(json => {
            this.setState({
                loading: false,
                group: json,
            })
        }).catch(res => {
            if (res.status === 404) this.setState({
                notFound: true
            })
        })
    }

    render() {
        if (this.state.notFound) return <NotFound />
        return (
            <div className="container mt-5 ">
                <div className="row align-items-start">
                    <div className="col-md-6">
                        <div className=" mt-3">
                            <div className="p-3 bg-light">
                                {
                                    this.state.loading ? <div className="d-flex justify-content-center">
                                        <SpinnerElement size={50} />
                                    </div> : <>
                                        <h1 className="m-0 mb-3 display-6" data-aos="fade-up">{this.state.group?.name}</h1>
                                        <h5>{this.state.group?.description}</h5>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className=" mt-3 ">
                            <div className="p-3 bg-light">
                                <h1 className="display-6 " data-aos="fade-up">Huidige Leden</h1>
                                <table className="table table-striped ">
                                    <thead>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Email</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.loading ? <tr>
                                                <td colSpan="3">
                                                    <div className="d-flex justify-content-center">
                                                        <SpinnerElement size={50} />
                                                    </div>
                                                </td>
                                            </tr> :
                                        this.state.group?.users?.map(user =>
                                            <tr key={user.id}>
                                                <th>{user.name}</th>
                                                <th>{user.email}</th>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ShowGroupElement = (props) => {
    const { id } = useParams();
    return <ShowGroupElementClass {...props} id={id} />
}

export default ShowGroupElement
