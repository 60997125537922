import './SpinnerElement.css'

const SpinnerElement = ({ size= 20 }) => {
    return (
        <div className="spinner-wrapper" style={{
            width: size+'px',
            height: size+'px'
        }}>
            <div className="spinner" />
        </div>
    )
}

export default SpinnerElement
