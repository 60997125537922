import {Link} from "react-router-dom";
import React from 'react';
import {ProjectService} from "../services/api/ProjectService";
import ProjectEventService from "../services/events/ProjectEventService";
import SpinnerElement from "../loading/SpinnerElement";
import {BreadcrumbContextSetter} from "../index";

class IndexProjectElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            removingProjects: [],
            loading: true,
        }
        IndexProjectElement.contextType = BreadcrumbContextSetter;
    }

    componentDidMount() {
        this.context({
            'Projects': '/projects'
        })
        ProjectService.getProjects().then(projects => {
            this.setState({
                projects: projects,
                loading: false,
            })
        });
        ProjectEventService.handleState(this)
    }

    componentWillUnmount() {
        ProjectEventService.unsubscribe(this)
    }

    handleDelete(e, id) {
        ProjectService.removeProject(id).then(() => {
            this.setState({
                removingProjects: this.state.removingProjects.filter(projectId => projectId !== id)
            })
        })
        this.setState({
            removingProjects: [
                ...this.state.removingProjects,
                id,
            ]
        })
    }

    render() {

        const user = this.props.user;
        const projects = this.state.projects;

        return (
            <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card px-2 py-5 shadow p-3 mb-5 bg-body rounded" data-aos="fade-up" data-aos-delay="300">
                            <h1> Project overzicht</h1>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Project</th>
                                    <th>Beschrijving</th>
                                    <th>Bewerken</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.loading ? <tr>
                                            <td colSpan="3">
                                                <div className="d-flex justify-content-center">
                                                    <SpinnerElement size={50} />
                                                </div>
                                            </td>
                                        </tr> :
                                    projects.map(project =>
                                        <tr key={project.id} className={`${this.state.removingProjects.includes(project.id) ? 'grayed-out' : ''}`}>
                                            <td>
                                                {project.name}
                                            </td>
                                            <td>
                                                {project.description}
                                            </td>
                                            { user.permissions.includes('manage projects') ?
                                                <td>
                                                    <div className="d-flex">
                                                        <Link className="btn btn-success" to={project.id + "/edit"}>
                                                            <i className="bi bi-pencil"/>
                                                        </Link>
                                                        <button className="btn btn-danger" onClick={e => this.handleDelete(e, project.id)}>
                                                            { this.state.removingProjects.includes(project.id) ?
                                                                <SpinnerElement size={25} /> :
                                                                <i className="bi bi-trash"/>
                                                            }
                                                        </button>
                                                    </div>
                                                </td> : <td />
                                            }
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                            { user.permissions.includes('manage projects') ?
                                <Link className="btn btn-orange mt-4" to="/projects/create">Project aanmaken</Link>
                                : <></> }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IndexProjectElement
