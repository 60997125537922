import React from "react";
import {Navigate, useParams} from "react-router-dom";
import {GroupService} from "../services/api/GroupService";
import {UserService} from "../services/api/UserService";
import {BreadcrumbContextSetter} from "../index";
import {NotFound} from "../error/NotFound";
import SpinnerElement from "../loading/SpinnerElement";

class EditGroupElementClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            members: [],
            groupLoading: true,
            usersLoading: true,
            saving: false,
            groupMembers: [],
            users: [],
            usernameFilter: '',
            redirect: false,
        }
        EditGroupElementClass.contextType = BreadcrumbContextSetter
    }

    updateContext() {
        this.context({
            'Groups': '/groups',
            [this.state.name ?? '']: `/groups/${this.props.id}`,
            'Edit': `/groups/${this.props.id}/edit`,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.name !== prevState.name) this.updateContext()
    }

    componentDidMount() {
        this.updateContext()

        GroupService.getGroup(this.props.id, {
            with: ['users']
        }).then(json => {
            this.setState({
                groupLoading: false,
                name: json.name,
                description: json.description,
                members: json.users.map(user => user.id),
                groupMembers: json.users
            })
        }).catch(res => {
            if (res.status === 404) {
                this.setState({
                    notFound: true
                })
            }
        })
        UserService.getUsers().then(json => {
            this.setState({
                usersLoading: false,
                users: json
            })
        })
    }

    removeMember(...ids) {
        const members = this.state.members;
        ids.forEach(id => members.splice(members.indexOf(id), 1));
        this.setState({
            members: members
        })
    }

    addMember(...ids) {
        const members = this.state.members;
        ids.forEach(id => members.push(id))
        this.setState({
            members: members
        })
    }

    handleSubmit(e) {
        this.setState({
            saving: true
        })
        const members = this.state.members;
        const groupMembers = this.state.groupMembers;
        GroupService.updateGroup(this.props.id, {
            group_name: this.state.name,
            group_description: this.state.description,
            add: members.filter(member => !groupMembers.map(groupMember => groupMember.id).includes(member)),
            remove: groupMembers.filter(groupMember => !members.includes(groupMember.id)).map(groupMember => groupMember.id)
        }).then(() => {
            this.setState({
                saving: false,
                redirect: true,
            })
        });
    }

    render() {
        if (this.state.notFound) return <NotFound />
        if (this.state.redirect) return <Navigate to={`/groups/${this.props.id}`} />
        return (
            <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="text-center mt-3">
                            <h1 className="display-4 text-center" data-aos="fade-up">Coachingsgroep bewerken</h1>
                            <div className={`row justify-content-center ${this.state.saving ? 'grayed-out' : ''}`}>

                                <div className="col-lg-10 col-sm-12 mt-3">
                                    <label htmlFor="group_name">Coachingsgroep Naam</label>
                                    <input disabled={this.state.groupLoading} name="group_name" id="group_name" type="text" className="form-control" value={this.state.name}
                                           onChange={e => this.setState({
                                               name: e.target.value
                                           })}
                                    />
                                </div>

                                <div className="col-lg-10 col-sm-12 mt-3">
                                    <label htmlFor="group_description">Coachingsgroep Beschrijving</label>
                                    <input disabled={this.state.groupLoading} name="group_description" id="group_description" type="text" className="form-control" value={this.state.description}
                                           onChange={e => this.setState({
                                               description: e.target.value
                                           })}
                                    />
                                </div>

                                <div className="col-4 p-3">
                                    <button disabled={this.state.groupLoading} className="btn btn-orange" onClick={(e) => this.handleSubmit(e)}>
                                        Opslaan
                                        {
                                            this.state.saving ? <div className="float-end">
                                                <SpinnerElement size={25} />
                                            </div> : <></>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <h1 className="display-4 text-center" data-aos="fade-up">Huidige Leden</h1>
                            <div className="row justify-content-center">
                                <table className="table table-striped ">
                                    <thead>
                                    <tr>
                                        <th>Gebruikersnaam</th>
                                        <th>Email</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.groupLoading ? <tr>
                                                <td colSpan="3">
                                                    <div className="d-flex justify-content-center">
                                                        <SpinnerElement size={40} />
                                                    </div>
                                                </td>
                                            </tr> :
                                        this.state.groupMembers.map(user =>
                                            <tr key={user.id}>
                                                <th>{user.name}</th>
                                                <th>{user.email}</th>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className="text-center mt-3">
                                <h1 className="display-4 text-center" data-aos="fade-up">Leden toevoegen</h1>
                                <div className="row justify-content-center">

                                    <table className="table table-striped ">
                                        <thead>
                                        <tr>
                                            <th>Selecteer</th>
                                            <th>Gebruikersnaam</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td/>
                                            <td>
                                                <input className="text-center" type="text" placeholder="filter"
                                                       onChange={(e) => this.setState({
                                                           usernameFilter: e.target.value
                                                       })}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            this.state.usersLoading ? <tr>
                                                    <td colSpan="3">
                                                        <div className="d-flex justify-content-center">
                                                            <SpinnerElement size={40} />
                                                        </div>
                                                    </td>
                                                </tr> :
                                            this.state.users.filter(user => user.name.toLowerCase().includes(this.state.usernameFilter.toLowerCase())).map(user =>
                                                <tr key={user.id}>
                                                    <td>
                                                        <input className="student-check" type="checkbox" defaultChecked={this.state.members.includes(user.id)}
                                                               onChange={e => e.target.checked ? this.addMember(user.id) : this.removeMember(user.id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {user.name}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    <button disabled={this.state.saving} id="submit-button"
                                            className={`btn btn-orange mb-5 d-flex justify-content-center ${this.state.saving ? 'grayed-out' : ''}`}
                                            onClick={e => this.handleSubmit(e)}>
                                        Opslaan
                                        {
                                            this.state.saving ? <div>
                                                <SpinnerElement size={25} />
                                            </div> : <></>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const EditGroupElement = (props) => {
    let { id } = useParams();
    return <EditGroupElementClass {...props} id={id} />
}

export default EditGroupElement
