import './DailyFormElement.css'
import DailyEmoteElement from "./DailyEmoteElement";
import {useState} from "react";
import {ImageService} from "../../index";
import SpinnerElement from "../../loading/SpinnerElement";

const DailyFormElement = ({ editable, data, handleSubmit }) => {

    const [hasComment, setHasComment] = useState(!!data.comment);
    const [score, setScore] = useState(data.mood_score ?? 3);
    const [description, setDescription] = useState(data.mood_description ?? '');
    const [hours, setHours] = useState(data.hours_worked ?? 0);
    const [comment, setComment] = useState(data.comment ?? '');
    const [saving, setSaving] = useState(false);

    function handleClick(e) {
        setSaving(true);
        handleSubmit(e, score, description, hours, comment);
    }

    return (
        <div className={`row justify-content-center mb-5 ${saving ? 'grayed-out' : ''}`}>
            <h1 className="text-center display-5 p-2">Hoe voel je je vandaag?</h1>
            <div className="form-floating m-3">
                {
                    [1, 2, 3, 4, 5].map(value =>
                        <DailyEmoteElement key={value} editable={editable} value={value} img={ImageService.getImage(`img${value}.png`)} checked={score === value}
                            onChange={(e, v) => setScore(v)}
                        />
                    )
                }
            </div>
            <div className="form-floating m-3">
                <textarea readOnly={!editable} name="mood_description" id="q9" placeholder="Toelichting:" value={description}
                          onChange={e => setDescription(e.target.value)}
                />
            </div>
            <h1 className="text-center display-5 p-2">Hoeveel heb je gewerkt aan het project gisteren?</h1>
            <div className="form-floating m-3">
                <h2 className="display-7 p-3" id="q10_output">{hours} uur</h2>
                <input disabled={!editable} value={hours} name="hours_worked" id="q10" type="range" className="form-range p-3 slider" min="0" max="10" step="1"
                       onChange={e => setHours(e.target.value)}
                />
            </div>
            <h1 className="text-center display-5 p-2">Heb je vragen?</h1>
            <div className="form-floating m-3">
                <div className="form-check form-check-inline">
                    <input defaultChecked={hasComment} disabled={!editable} type="radio" className="form-check-input" name="question" id="q11_Ja" value="Ja"
                           onClick={e => setHasComment(true)}
                    />
                    <label htmlFor="q11_Ja">Ja</label>
                </div>

                <div className="form-check form-check-inline">
                    <input defaultChecked={!hasComment} disabled={!editable} type="radio" className="form-check-input" name="question" id="q11_Nee" value="Nee"
                           onClick={e => {
                               setComment('');
                               setHasComment(false);
                           }}
                    />
                    <label htmlFor="q11_Nee">Nee</label>
                </div>
            </div>
            <div className="form-floating me-5 pe-5 ps-5 ms-5">
                <textarea readOnly={!editable} hidden={!hasComment} name="comment" id="q12" className="form-control" value={comment}
                          onChange={e => setComment(e.target.value)}
                />
            </div>

            { editable ?
                <div className="col-4 p-3">
                    <button className="btn btn-orange" onClick={handleClick}>
                        Opslaan
                        { saving ? <div className="float-end">
                            <SpinnerElement size={25} />
                        </div>: <></> }
                    </button>
                </div> : <></>
            }
        </div>
    )
}

export default DailyFormElement
