import React from "react";
import StudentenDashboardElement from "./student/StudentenDashboardElement";
import DocentenDashboardElement from "./docent/DocentenDashboardElement";
import {BreadcrumbContextSetter} from "../index";

class DashboardElement extends React.Component {

    constructor(props) {
        super(props);
        DashboardElement.contextType = BreadcrumbContextSetter
    }

    componentDidMount() {
        this.context({
            'Dashboard': '/dashboard'
        })
    }

    render() {
        if (this.props.user.permissions?.includes('use docentendashboard')) return <DocentenDashboardElement {...this.props} />
        else return <StudentenDashboardElement {...this.props} />
    }
}

export default DashboardElement
