import {Link} from "react-router-dom";
import React from 'react';
import {UserService} from "../services/api/UserService";
import SpinnerElement from "../loading/SpinnerElement";

class IndexCheckinElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            removingDailies: [],
            removingWeeklies: [],
            loading: true,
        }
    }

    componentDidMount() {
        UserService.getUser(this.props.user.id, {
            with: ['dailyCheckins', 'weeklyCheckins', 'canDoDaily', 'canDoWeekly']
        }).then(user => {
            this.setState({
                user: user,
            })
        }).finally(() => this.setState({
            loading: false,
        }))
    }

    handleDelete(e, type, id) {
        if (type === 'daily') {
            this.setState({
                removingDailies: [...this.state.removingDailies, id]
            })
        } else if (type === 'weekly') {
            this.setState({
                removingWeeklies: [...this.state.removingWeeklies, id]
            })
        }
        UserService.removeCheckin(this.props.user.id, id, type).then(() => {
            if (type === 'daily') {
                this.setState({
                    user: {
                        ...this.state.user,
                        daily_checkins: this.state.user['daily_checkins'].filter(daily => daily.id !== id)
                    }
                })
            } else if (type === 'weekly') {
                this.setState({
                    user: {
                        ...this.state.user,
                        weekly_checkins: this.state.user['weekly_checkins'].filter(weekly => weekly.id !== id)
                    }
                })
            }
        }).finally(() => {
            if (type === 'daily') {
                this.setState({
                    removingDailies: this.state.removingDailies.filter(daily => daily.id !== id)
                })
            } else if (type === 'weekly') {
                this.setState({
                    removingWeeklies: this.state.removingWeeklies.filter(weekly => weekly.id !== id)
                })
            }
        })
    }

    render() {
        const canDoDaily = this.state.user['can_do_daily'];
        const canDoWeekly = this.state.user['can_do_weekly'];
        const checkins = [
            ...this.state.user['daily_checkins']?.map(daily => {
                return {
                    ...daily,
                    date: new Date(daily.created_at).toLocaleDateString(),
                    type: 'daily'
                }
            }) ?? [],
            ...this.state.user['weekly_checkins']?.map(weekly => {
                return {
                    ...weekly,
                    date: new Date(weekly.created_at).toLocaleDateString(),
                    type: 'weekly'
                }
            }) ?? [],
        ]

        return (
            <div className="row justify-content-center text-center">
                <h1 className="display-4 text-center" data-aos="fade-up">Check-in antwoorden</h1>

                { canDoDaily || canDoWeekly ?
                    <div className="col-8 text-center">
                        {canDoDaily ? <Link className="mt-4 btn btn-orange" to="daily/create">Daily Check-in invullen</Link> : <></>}
                        {canDoWeekly ? <Link className="mt-4 btn btn-orange" to="weekly/create">Weekly Check-in invullen</Link> : <></>}
                    </div> : <></>
                }

                <div className="row mt-5 justify-content-center">
                    <div className="col-8">
                        <table className="table table-striped table-responsive table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Datum</th>
                                <th scope="col">Acties</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td className="text-center" colSpan="3">
                                            <div className="d-flex justify-content-center">
                                                <SpinnerElement size={40} />
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    checkins.map(checkin => {
                                        const isLoading = (checkin.type === 'daily' && this.state.removingDailies.includes(checkin.id) ||
                                            checkin.type === 'weekly' && this.state.removingWeeklies.includes(checkin.id));
                                        return <tr className={`fade-in-right${isLoading ? ' grayed-out' : ''}`} key={checkin.type + checkin.id}>
                                            <td>{checkin.type}</td>
                                            <td>{checkin.date}</td>
                                            <td>
                                                <Link className="btn btn-primary" to={checkin.type + '/' + checkin.id}>
                                                    <i className="bi bi-eye"/>
                                                </Link>
                                                <Link className="btn btn-success" to={checkin.type + '/' + checkin.id + '/edit'}>
                                                    <i className="bi bi-pencil"/>
                                                </Link>
                                                <button className="btn btn-danger" onClick={e => this.handleDelete(e, checkin.type, checkin.id)}>
                                                    <i className="bi bi-trash"/>
                                                </button>
                                                {
                                                    isLoading ? <div className="float-end">
                                                        <SpinnerElement size={40} />
                                                    </div> : <></>
                                                }
                                            </td>
                                        </tr>
                                    })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default IndexCheckinElement
