import WeeklyFormElement from "./WeeklyFormElement";
import {useParams, Navigate} from "react-router-dom";
import React from 'react';
import {UserService} from "../../services/api/UserService";
import SpinnerElement from "../../loading/SpinnerElement";
import {NotFound} from "../../error/NotFound";

class ShowWeeklyElementClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkin: {},
            redirect: false,
            loading: true,
        }
    }

    componentDidMount() {
        UserService.getUser(this.props.user.id, {
            with: ['weeklyCheckins']
        }).then(user => {
            const checkin = user['weekly_checkins'].filter(weekly => weekly.id === +this.props.id)[0];
            if (checkin) this.setState({
                checkin: checkin,
                loading: false,
            });
            else this.setState({
                notFound: true,
            })
        }).catch(res => {
            if (res.status === 404) {
                this.setState({
                    notFound: true
                })
            }
        })
    }

    render() {
        if (this.state.notFound) return <NotFound />
        return (
            <div className="row justify-content-center align-items-center mt-5">
                {this.state.redirect ? <Navigate to="/checkins" /> : <></>}
                <div className="col-lg-6 col-md-8 col-sm-10 text-center shadow mb-5 bg-body rounded-3">
                    <div className="row bg-orange text-white p-3 rounded-top">
                        <h1 className="display-4">Weekly Check-in</h1>
                    </div>

                    {
                        this.state.loading ?
                            <div className="d-flex justify-content-center m-5">
                                <SpinnerElement size={80}/>
                            </div> :
                            Object.keys(this.state.checkin).length ?
                                <WeeklyFormElement editable={false} data={this.state.checkin} />
                                : <></>
                    }

                </div>
            </div>
        )
    }
}

const ShowWeeklyElement = (props) => {
    const params = useParams();
    return <ShowWeeklyElementClass {...props} id={params.checkinId} />
}

export default ShowWeeklyElement
