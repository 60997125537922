import Echo from "laravel-echo";

class EventService {

    registeredEventHandlers = {}

    constructor(options) {
        this.options = options
        this.connection = new Echo({
            ...this.options,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            },
        });
    }

    restart() {
        this.connection = new Echo({
            ...this.options,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            },
        });
    }

    handleIncomingEvent(event, data) {
        Object.values(this.registeredEventHandlers[event]).forEach(callback => callback(data))
    }

    subscribe(channel, event, identifier, callback) {
        if (event === '.App\\Events\\User\\Jobrole\\UserJobroleAttached') console.log(this.registeredEventHandlers)
        if (!this.registeredEventHandlers[event] || !this.registeredEventHandlers[event].length) {
            this.registeredEventHandlers[event] = {
                [identifier]: callback,
            }
            this.connection.private(channel).listen(event, (data) => this.handleIncomingEvent(event, data))
        } else {
            this.registeredEventHandlers[event][identifier] = callback
        }
    }

    unsubscribe(channel, event, identifier) {
        if (!this.registeredEventHandlers[event]) return
        delete this.registeredEventHandlers[event][identifier]
        if (!Object.keys(this.registeredEventHandlers[event]).length) {
            this.connection.private(channel).stopListening(event)
        }
    }



    stateHasField(state, field, wrap) {
        return (wrap && Array.isArray(state[wrap][field])) || (!wrap && Array.isArray(state[field]))
    }

    makeAttachedStateChanged(state, field, object, wrap) {
        return wrap ?
            {
                [wrap]: {...state[wrap], [field]: [...state[wrap][field], ...object]}
            }         : {[field]: [...state[field], ...object]};
    }

    makeDetachedStateChanged(state, field, object, wrap) {
        return wrap ?
            {
                [wrap]: {...state[wrap], [field]: state[wrap][field].filter(stateObject => !object.includes(stateObject.id))}
            }         : {[field]: state[field].filter(stateObject => !object.includes(stateObject.id))};
    }
}

export default EventService;
