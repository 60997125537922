import React, {Component} from "react";

import styles from "./uploadContainer.module.css"
import AddDescription from "./addDescription"
import AddFile from "./addFile";
import AddFeedback from "./addFeedback";
import CubeComponent from "./cubeComponent";
import { v4 as uuidv4 } from "uuid";
import uploadFile from "../services/fileUploaderService";

class UploadContainer extends Component
{
    state = {
        description: "",
        file: "",
        feedback_assessor: "",
        feedback_aspects: "",
        files: [],
        input: <input type="file" className="form-control" id="inputGroupFile03"
                      aria-describedby="inputGroupFileAddon03" aria-label="Upload" />

    }

    // componentWillMount() {
    //     if
    // }

    addDescription = desc => {
        this.setState({description: desc})
    }

    addFile = file => {
        const item = {
            id: uuidv4(),
            name: file.name,
            file: file.file,
            type: file.type

        }
        this.setState({files: [...this.state.files, item]
        })
    }

    delFile = item => {
        this.setState({
            files: [
                ...this.state.files.filter(file => {
                    return file.id !== item
                })
            ]
        })
    }

    addFeedbackAssessor = assessor => {
        this.setState({feedback_assessor: assessor})
    }

    addFeedbackAspects = aspects => {
        this.setState({feedback_aspects: aspects})
    }

    handleSubmit = () => {
        this.state.files.map(
            file => {
                uploadFile(file)
            }
        )
    }

    componentDidMount() {
        console.log(localStorage.getItem("token"))
    }

    render() {
        return(
            <div>
                <div className={styles.headerContainer}>
                    <h1>Voeg beroepsproduct toe</h1>
                </div>
                <div className={styles.container}>
                    <div className="form-control">
                        <div className={styles.description}>
                            <AddDescription addDesc={this.addDescription} />
                        </div>
                        <AddFile addFile={this.addFile} delFile={this.delFile} files={this.state.files}/>
                        <div className={styles.bottomContainer}>
                            <AddFeedback addAssessor={this.addFeedbackAssessor} addAspects={this.addFeedbackAspects}/>
                            <CubeComponent />
                        </div>
                    </div>
                    <button onClick={this.handleSubmit} className={"btn btn-success"} style={{marginTop: "2%"}}>Voeg toe</button>
                </div>
            </div>
        )
    }
}

export default UploadContainer
