import React from "react";
import {ProjectService} from "../services/api/ProjectService";
import {Navigate} from "react-router-dom";
import SpinnerElement from "../loading/SpinnerElement";
import {BreadcrumbContextSetter} from "../index";

class CreateProjectElement extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
        }
        CreateProjectElement.contextType = BreadcrumbContextSetter
    }

    handleSumbit(e) {
        e.preventDefault()
        this.setState({
            loading: true
        })
        ProjectService.addProject({
            name: e.nativeEvent.target[0].value,
            description: e.nativeEvent.target[1].value
        }).then(() => {
            this.setState({
                redirect: true
            })
        })
    }

    componentDidMount() {
        this.context({
            'Projects': '/projects',
            'Create': '/projects/create'
        })
    }

    render() {
        if (this.state.redirect) return <Navigate to="/projects"/>
        return (
            <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className={`text-center mt-3${this.state.loading ? ' grayed-out' : ''}`}>
                            <h1 className="display-4 text-center" data-aos="fade-up">Project aanmaken</h1>
                            <div className="row justify-content-center">
                                <form id="create-project-form" hidden onSubmit={e => this.handleSumbit(e)} />
                                <div className="col-lg-10 col-sm-12 mt-3">
                                    <label htmlFor="project_name">Project Naam</label>
                                    <input form="create-project-form" name="name" id="project_name" type="text" className="form-control" />
                                </div>

                                <div className="col-lg-10 col-sm-12 mt-3">
                                    <label htmlFor="project_description">Project Beschrijving</label>
                                    <input form="create-project-form" name="description" id="project_description" type="text" className="form-control" />
                                </div>

                                <div className="col-4 p-3 d-flex justify-content-center">
                                    <button form="create-project-form" type="submit" className="btn btn-orange d-flex justify-content-center">
                                        Opslaan
                                        { this.state.loading ? <SpinnerElement size={25} /> : <></> }
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateProjectElement
