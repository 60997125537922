import React, {useState} from "react";
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import styles from "./addFeedback.module.css"

const AddFeedback = (props) => {
    const [assessor, setAssessor] = useState('')
    const [value, setValue] = useState('')

    const handleOnchange = val => {
        setValue(val)
        props.addAspects(value.split(","))
    }

    const  options  = [
        { label:  'Option 1', value:  'option_1'  },
        { label:  'Option 2', value:  'option_2'  },
        { label:  'Option 3', value:  'option_3'  },
        { label:  'Option 4', value:  'option_4'  },
    ]

    const addAssessor = e => {
        setAssessor(e.target.value);
        props.addAssessor(assessor);
    }

    return (
        <form>
            <h2>Feedback aanvragen</h2>
            <div className={styles.container}>
                <div>
                    <label>Van wie wil je feedback?
                        <input type="text" value={assessor} onChange={addAssessor} placeholder="Vul een e-mailadres is"/>
                    </label>
                </div>
                <div>
                    <label>Selecteer aspecten
                        <MultiSelect
                            onChange={handleOnchange}
                            options={options}
                        />
                    </label>
                </div>
            </div>
        </form>
    )
}

export default AddFeedback
