import {Link} from "react-router-dom";
import React from "react";

const CheckinButtonsElement = ({ canDoDaily, canDoWeekly }) => {
    return (
        <>
            {canDoDaily || canDoWeekly ? <p className="fs-3">Check-in invullen</p> : <></>}
            {canDoDaily ? <Link className="btn btn-orange" to="/checkins/daily/create">Daily Check-in</Link> : <></>}
            {canDoWeekly ? <Link className="btn btn-orange" to="/checkins/weekly/create">Weekly Check-in</Link> : <></>}
            <p className={"fs-3" +
                canDoDaily || canDoWeekly ? "mt-5" : ""
            }>Check-in bekijken</p>
        </>
    );
}

export default CheckinButtonsElement
