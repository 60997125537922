import {Link, Navigate} from "react-router-dom";
import {BreadcrumbContextSetter, ImageService, UserContextSetter} from "../index";
import React, {useContext} from 'react'
import {UserService} from "../services/api/UserService";
import SpinnerElement from "../loading/SpinnerElement";

class EditProfileElementClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: props.user.email,
            name: props.user.name,
            password: '',
            passwordRepeat: '',
            loading: false,
            redirect: false,
        }
        EditProfileElementClass.contextType = BreadcrumbContextSetter;
    }

    componentDidMount() {
        this.context({
            'Profile': '/profile',
            'Edit': '/profile/edit'
        })
    }

    handleEditUser(email, password, passwordRepeat) {
        if (!password && !passwordRepeat && email === this.props.user.email) return;
        let body = {};
        if (password || passwordRepeat) {
            if (password === passwordRepeat && password.length >= 8) {
                body = {
                    ...body,
                    password: password
                }
            }
        }
        if (email !== this.props.user.email) {
            body = {
                ...body,
                email: email,
            }
        }
        this.setState({
            loading: true,
        });
        UserService.updateUser(this.props.user.id, body).then(() => {
            UserService.getSelf().then(user => this.props.setUser(user));
            this.setState({
                redirect: true
            })
        })
    }

    render() {
        const user = this.props.user;
        const userType = user.roles[0] ?? 'User';

        return (
            <div className="container mt-5">
                { this.state.redirect ? <Navigate to="/profile"/> : <></> }
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card px-2 py-5 shadow p-3 mb-5 bg-body rounded" data-aos="fade-up"
                             data-aos-delay="300">
                            <div className="text-center">
                                <img src={ImageService.getImage('thumbnail.png')} className="rounded-circle w-25"
                                     alt="Profile"/>
                            </div>
                            <div className="text-center mt-3">
                                <span className="bg-secondary p-1 px-4 rounded text-white">{userType}</span>
                                <br/>
                                <br/>
                                <Link to="/profile">Terug naar het profiel</Link>

                                <div className={`row justify-content-center${this.state.loading ? ' grayed-out' : ''}`}>
                                    <div className="col-lg-10 col-sm-12 mt-3">
                                        <label htmlFor="email">Email</label>
                                        <input name="email" id="email" type="email" className="form-control"
                                               value={this.state.email}
                                               onChange={e => this.setState({email: e.target.value})}
                                        />
                                    </div>

                                    <div className="col-lg-10 col-sm-12 mt-3">
                                        <label htmlFor="name">Naam</label>
                                        <input disabled name="name" id="name" type="text" className="form-control"
                                               value={this.state.name}
                                        />
                                    </div>

                                    <div className="col-lg-10 col-sm-12 mt-3">
                                        <label htmlFor="password">Nieuw wachtwoord</label>
                                        <input name="password" id="password" type="password" className="form-control"
                                               onChange={e => this.setState({password: e.target.value})}
                                        />
                                    </div>

                                    <div className="col-lg-10 col-sm-12 mt-3">
                                        <label htmlFor="password_repeat">Nieuw wachtwoord (herhaling)</label>
                                        <input name="password_repeat" id="password_repeat" type="password"
                                               className="form-control"
                                               onChange={e => this.setState({passwordRepeat: e.target.value})}
                                        />
                                    </div>

                                    <div className="col-4 p-3 d-flex justify-content-center">
                                        <button className="btn btn-orange d-flex align-items-center" onClick={e => this.handleEditUser(
                                            this.state.email,
                                            this.state.password,
                                            this.state.passwordRepeat,
                                        )}>
                                            Opslaan
                                            {this.state.loading ? <SpinnerElement size={25} /> : <></> }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const EditProfileElement = (props) => {
    const userContextSetter = useContext(UserContextSetter)
    return <EditProfileElementClass {...props} setUser={userContextSetter} />
}

export default EditProfileElement
