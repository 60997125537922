import {Api} from "../../index";

export class GroupService {
    static getGroups(body) {
        return Api.call('/groups', 'GET', body)
    }

    static getGroup(id, body) {
        return Api.call('/groups/' + id, 'GET', body)
    }

    static updateGroup(id, body) {
        return Promise.all([
            Api.call('/groups/' + id, 'PUT', body),
            ...body.add.map(userId => Api.call('/groups/' + id + '/users/' + userId, 'PUT')),
            ...body.remove.map(userId => Api.call('/groups/' + id + '/users/' + userId, 'DELETE'))
        ]);
    }

    static deleteGroup(id) {
        return Api.call('/groups/' + id, 'DELETE')
    }

    static addGroup(body) {
        return Api.call('/groups', 'POST', body)
    }

    static getHappiness(id, from, to) {
        let url = `/groups/${id}/happiness?`
        if (from) url += `from=${from}&`
        if (to) url += `to=${to}`
        return Api.call(url);
    }
}
