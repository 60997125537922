import {Link} from "react-router-dom";
import {GroupService} from "../services/api/GroupService";
import {BreadcrumbContextSetter, EventListener} from '../index';
import SpinnerElement from "../loading/SpinnerElement";
import React from 'react';

class IndexGroupElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            loading: true,
            loadingGroups: [],
        }
        IndexGroupElement.contextType = BreadcrumbContextSetter
    }

    componentDidMount() {
        this.context({
            'Groups': '/groups'
        })
        GroupService.getGroups().then(json => {
            this.setState({
                loading: false,
                groups: json,
            })
        })
        EventListener.private('groups').listen('GroupsChanged', (e) => {
            this.setState({
                groups: e,
                loadingGroups: this.state.loadingGroups.filter(loadingGroup => e.map(group => group.id).includes(loadingGroup)),
            })
        });
    }

    handleDelete(e, id) {
        GroupService.deleteGroup(id).then(() => this.setState({
            groups: this.state.groups.filter(group => group.id !== id)
        }));
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <h1 className="display-4 " data-aos="fade-up">Overzicht coachingsgroepen
                        <Link className="btn btn-success" to="create">
                            Nieuwe coachingsgroep maken
                            <i className="bi"/>
                        </Link>
                    </h1>
                    <table className="table table-striped ">
                        <thead>
                        <tr>
                            <th>Groepsnaam</th>
                            <th>Groepsbeschrijving</th>
                            <th>bewerkingen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <th className="text-center" colSpan="3">
                                        <div className="d-flex justify-content-center">
                                            <SpinnerElement size={40} />
                                        </div>
                                    </th>
                                </tr>
                                :
                                this.state.groups.map(group =>
                                    <tr className={`fade-in-right${this.state.loadingGroups.includes(group.id) ? ' grayed-out' : ''}`} key={group.id}>
                                        <th>{group.name}</th>
                                        <th>{group.description}</th>
                                        <th>
                                            <Link className="btn btn-orange" to={''+group.id}>
                                                <i className="bi bi-eye"/>
                                            </Link>
                                            <Link className="btn btn-success" to={group.id + '/edit'}>
                                                <i className="bi bi-pencil"/>
                                            </Link>

                                            <button className="btn btn-danger" onClick={e => {
                                                this.setState({
                                                    loadingGroups: [...this.state.loadingGroups, group.id]
                                                })
                                                this.handleDelete(e, group.id);
                                            }}>
                                                <i className="bi bi-trash" />
                                            </button>

                                            {
                                                this.state.loadingGroups.includes(group.id) ? <div className="float-end">
                                                    <SpinnerElement size={40} />
                                                </div> : <></>
                                            }
                                        </th>
                                    </tr>
                                )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default IndexGroupElement
