import React, {useState} from "react";
import UploadedFile from "./uploadedFile";
import styles from "./uploadedFilesList.module.css"

const UploadedFilesList = (props) => {

    return (
        <div className={styles.itemsContainer}>
            {props.files.map(file => {
                return (
                    <UploadedFile file={file} delFile={props.delFile}/>
                )
            })}
        </div>
    )
}

export default UploadedFilesList
