import {Link, useParams} from "react-router-dom";
import React from 'react';
import {UserService} from "../../services/api/UserService";
import {Chart} from "chart.js";
import {BreadcrumbContextSetter} from "../../index";
import {NotFound} from "../../error/NotFound";
import SpinnerElement from "../../loading/SpinnerElement";

class StudentDetailsElementClass extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: {
                daily_checkins: [],
            },
            loading: true,
        }
        this.onResize = null;
        StudentDetailsElementClass.contextType = BreadcrumbContextSetter
    }

    updateContext() {
        this.context({
            'Dashboard': '/dashboard',
            [this.state.user.name ?? '']: `/dashboard/${this.state.user.id}`
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.user.name !== prevState.user.name) this.updateContext()
    }

    componentDidMount() {
        this.updateContext()
        UserService.getUser(this.props.id, {with: ["dailyCheckins", "happiness"]}).then(user => {
            this.setState({
                user: user,
                loading: false,
            })
            this.setUserGraphDocentDashboard(user['happiness'])
        }).catch(res => {
            if (res.status === 404) {
                this.setState({
                    notFound: true
                })
            }
        })
    }

    setUserGraphDocentDashboard(answersPoints) {
        const labelData = Object.keys(answersPoints);
        const graphData = Object.values(answersPoints);

        const ctx_user = document.getElementById('userChartDocentDashboard');
        let chart = new Chart(ctx_user, {
            type: 'line',
            data: {
                labels: labelData,
                datasets: [{
                    label: 'Hoe voel je je vandaag?',
                    data: graphData,
                    borderColor: 'rgb(75, 192, 192)',
                }]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        min: 0,
                        beginAtZero: true
                    }
                }
            }
        });
        this.onResize = e => chart.resize()
        window.addEventListener('resize', this.onResize)
    }

    render() {
        if (this.state.notFound) return <NotFound/>

        const student = this.state.user.name;
        const dailyCheckins = this.state.user['daily_checkins']
        dailyCheckins.length = 5;

        return (
            <div className="row justify-content-center mt-5">
                <h1 className="display-4 text-center">Details {student}</h1>

                <div className="col-6 mt-5 shadow p-3 mb-5 bg-body rounded">
                    <div hidden={this.state.loading}>
                        <canvas id="userChartDocentDashboard" />
                    </div>
                </div>
                <div className="col-4 mt-5 shadow p-3 mb-5 bg-body rounded offset-1">
                    <h2>HBO-I Competenties</h2>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Competentie</th>
                            <th>Niveau</th>
                            <th>Doel</th>
                        </tr>
                        </thead>
                        <tbody></tbody>
                    </table>

                    <h2>Daily Checkins</h2>
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Datum</th>
                            <th scope="col">Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ? <tr>
                                    <td colSpan="3">
                                        <div className="d-flex justify-content-center">
                                            <SpinnerElement size={40} />
                                        </div>
                                    </td>
                                </tr> :
                                dailyCheckins.map(checkin =>
                                    <tr key={checkin.id}>
                                        <td>Daily</td>
                                        <td>{new Date(checkin['created_at']).toLocaleDateString()}</td>
                                        <td>
                                            <Link className="btn btn-orange" to={`checkins/daily/${checkin.id}`}>
                                                <i className="bi bi-eye"/>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const StudentDetailsElement = () => {
    const { id } = useParams();
    return <StudentDetailsElementClass id={id} />
}

export default StudentDetailsElement
