import {Api} from "../../index";

export class ProjectService {

    static getProjects(body) {
        return Api.call('/projects', 'GET', body)
    }

    static getProject(id, body) {
        return Api.call(`/projects/${id}`, 'GET', body)
    }

    static addProject(body) {
        return Api.call('/projects', 'POST', body)
    }

    static removeProject(id) {
        return Api.call(`/projects/${id}`, 'DELETE')
    }

    static editProject(id, body) {
        return Promise.all([
            Api.call('/projects/' + id, 'PUT', body),
            ...body.add.map(userId => Api.call('/projects/' + id + '/users/' + userId, 'PUT')),
            ...body.remove.map(userId => Api.call('/projects/' + id + '/users/' + userId, 'DELETE'))
        ]);
    }
}
