import {Api} from "../../index";

export class UserService {
    static getSelf() {
        return Api.call('/whoami');
    }

    static getUser(id, body) {
        return Api.call(`/users/${id}`, 'GET', body);
    }

    static getUsers(body) {
        return Api.call('/users', 'GET', body);
    }

    static removeJobrole(userId, jobroleId) {
        return Api.call(`/users/${userId}/jobroles/${jobroleId}`, 'DELETE');
    }

    static addJobrole(userId, jobroleId) {
        return Api.call(`/users/${userId}/jobroles/${jobroleId}`, 'PUT');
    }

    static updateUser(id, body) {
        return Api.call(`/users/${id}`, 'PUT', body);
    }

    static addUser(body) {
        return Api.call('/users', 'POST', body)
    }

    static getGroups(id, body) {
        return Api.call(`/users/${id}/groups`, 'GET', body)
    }

    static removeCheckin(userId, checkinId, type) {
        return Api.call(`/users/${userId}/${type}-checkins/${checkinId}`, 'DELETE')
    }

    static createCheckin(userId, type, body) {
        return Api.call(`/users/${userId}/${type}-checkins`, 'POST', body)
    }

    static updateCheckin(userId, checkinId, type, body) {
        return Api.call(`/users/${userId}/${type}-checkins/${checkinId}`, 'PUT', body)
    }

    static removeNotification(userId, notiId) {
        return Api.call(`/users/${userId}/notifications/${notiId}`, 'DELETE')
    }
}
