import React from "react";
import {GroupService} from "../services/api/GroupService";
import {Navigate} from "react-router-dom";
import SpinnerElement from "../loading/SpinnerElement";
import {BreadcrumbContextSetter} from "../index";

class CreateGroupElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            loading: false,
            redirect: false,
        }
        CreateGroupElement.contextType = BreadcrumbContextSetter
    }

    handleSubmit(e) {
        GroupService.addGroup({
            group_name: this.state.name,
            group_description: this.state.description
        }).then(() => {
            this.setState({
                loading: false,
                redirect: true
            })
        })
    }

    componentDidMount() {
        this.context({
            'Groups': '/groups',
            'Create': '/groups/create'
        })
    }

    render() {
        if (this.state.redirect) return <Navigate to="/groups" />
        return (
            <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="text-center mt-3">
                            <h1 className="display-4 text-center" data-aos="fade-up">Coachingsgroep aanmaken</h1>
                            <div className={`row justify-content-center${this.state.loading ? ' grayed-out' : ''}`}>

                                <div className="col-lg-10 col-sm-12 mt-3">
                                    <label htmlFor="group_name">Coachingsgroep Naam</label>
                                    <input name="group_name" id="group_name" type="text" className="form-control"
                                           onChange={e => this.setState({
                                               name: e.target.value
                                           })}
                                    />
                                </div>

                                <div className="col-lg-10 col-sm-12 mt-3">
                                    <label htmlFor="group_description">Coachingsgroep Beschrijving</label>
                                    <input name="group_description" id="group_description" type="text" className="form-control"
                                           onChange={e => this.setState({
                                               description: e.target.value
                                           })}
                                    />
                                </div>

                                <div className="col-4 p-3">
                                    <button className="btn btn-orange" onClick={e => {
                                        this.handleSubmit(e);
                                        this.setState({
                                            loading: true
                                        })
                                    }}>Opslaan
                                        { this.state.loading ? <div className="float-end">
                                            <SpinnerElement size={25} />
                                        </div>: <></> }
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateGroupElement
