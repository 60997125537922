import {EventService} from '../../index'

class JobroleEventService {
    static subscribe(event, callback) {
        EventService.subscribe('jobroles', event, callback)
    }

    static subscribeWithId(id, event, callback) {
        EventService.subscribe(`jobroles.${id}`, event, callback)
    }

    static handleState(component) {
        this.subscribe('.JobroleCreated', (jobrole) => {
            component.setState({
                jobroles: [...component.state.jobroles, jobrole]
            })
        });
        this.subscribe('.JobroleDeleted', (jobrole) => {
            component.setState({
                jobroles: component.state.jobroles.filter(stateJobrole => stateJobrole.id !== jobrole.id)
            })
        });
        this.subscribe('.JobroleUpdated', (jobrole) => {
            component.setState({
                jobroles: [
                    ...component.state.jobroles.filter(stateJobrole => stateJobrole.id !== jobrole.id),
                    ...component.state.jobroles.filter(stateJobrole => stateJobrole.id === jobrole.id).map(() => jobrole),
                ]
            })
        });
    }

    static handleStateWithId(component, id) {
        this.subscribeWithId(id, `.JobroleUpdated`, (jobrole) => {
            component.setState({
                ...jobrole
            })
        });
    }

    static unsubscribe() {
        EventService.unsubscribe('jobroles')
    }

    static unsubscribeWithId(id) {
        EventService.unsubscribe(`jobroles.${id}`)
    }
}

export default JobroleEventService
