import WeeklyFormElement from "./WeeklyFormElement";
import {UserService} from "../../services/api/UserService";
import React from 'react';
import {Navigate} from 'react-router-dom';

class CreateWeeklyElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    handleSubmit(e, thingsLearned, thingsDone, comment) {
        UserService.createCheckin(this.props.user.id, 'weekly', {
            things_learned: thingsLearned,
            things_done: thingsDone,
            comment: comment
        }).then(() => this.setState({redirect: true}))
    }

    render() {

        return (
            <div className="row justify-content-center align-items-center mt-5">
                {this.state.redirect ? <Navigate to="/checkins" /> : <></>}
                <div className="col-lg-6 col-md-8 col-sm-10 text-center shadow mb-5 bg-body rounded-3">
                    <div className="row bg-orange text-white p-3 rounded-top">
                        <h1 className="display-4">Weekly Check-in</h1>
                    </div>

                    <WeeklyFormElement editable={true} data={{}} handleSubmit={(e, thingsLearned, thingsDone, comment) => this.handleSubmit(e, thingsLearned, thingsDone, comment)} />

                </div>
            </div>
        )
    }
}

export default CreateWeeklyElement
